import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

import Automation from "./views/Automation.vue";
import AutomationStartApp from "./views/StartApp.vue";
import KnowledgeAssetFinder from "./components/FileViewer/KnowledgeAssetFinder.vue";
import Chat from "./views/Chat.vue";
import ChatSearch from "./views/ChatSearch.vue";
import Login from "./views/Login.vue";
import Start from "./views/Start.vue";
import config from "@/customer_configs_flags/config";
import { getAuth } from "firebase/auth";
import store from "./store";
import NotFound from "./views/NotFound.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "start",
    component: Start,
    beforeEnter: async (to, from, next) => {
      if (store.getters["auth/hasAuth"]) {
        next();
      } else {
        next("/login");
      }
    },
    redirect: () => {
      return { name: config.useCases[0].name };
    },
    children: [
      {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

config.useCases.forEach((useCase) => {
  const useCaseBasePath = `/${useCase.route}`;
  routes[0].children.push({
    path: useCaseBasePath,
    component: ChatSearch,
    name: useCase.name,
    meta: { requiresAuth: true },
    props: { useCase: useCase.name },
  });
  routes[0].children.push({
    path: `${useCaseBasePath}/knowledge-asset-finder/:assetName`,
    component: KnowledgeAssetFinder,
    name: `${useCase.name}-KnowledgeAssetFinder`,
    meta: { requiresAuth: true },
  });
});

if (config.enableChatTab) {
  routes[0].children.push({
    path: "/chat",
    component: Chat,
    name: "chat",
    meta: { requiresAuth: true },
  });
}

if (config.enableSkillsTab) {
  routes[0].children.push({
    path: "/skills",
    component: Automation,
    children: [
      {
        path: "/skills",
        component: AutomationStartApp,
        name: "skills",
        meta: { requiresAuth: true },
      },
    ],
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const auth = getAuth();

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;

  if (requiresAuth && !isAuthenticated) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
