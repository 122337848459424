<template>
  <div class="overflow-auto pb-10 px-8 mt-5 w-full">
    <div class="w-full px-4">
      <h1 class="text-xl font-bold mt-3 p-6 text-gray-900">
        <font-awesome-icon :icon="['fas', 'folder-open']" /> {{ assetLabel }}
      </h1>
    </div>
    <div class="w-full px-4">
      <div class="p-6">
        <file-pond
          name="file"
          ref="pond"
          :label-idle="$t('knowledge_asset_finder.label_idle')"
          allow-multiple="true"
          accepted-file-types="application/pdf"
          :server="serverConfig"
          credits="false" />
      </div>
    </div>

    <div class="w-full px-4 mb-6">
      <div class="p-6">
        <div class="w-full px-4 mb-6">
          <div
            class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div class="flex flex-wrap w-full md:w-2/3">
              <button
                v-for="filter in filters"
                :key="filter.type"
                @click="currentFilter = filter.type"
                :class="{
                  'bg-gray-100 ': currentFilter === filter.type,
                }"
                class="text-sm py-1.5 px-4 mr-2 mb-2 rounded-lg hover:bg-gray-100 hidden">
                <font-awesome-icon :icon="filter.icon" class="inline-block mr-1 text-gray-700" />
                {{ filter.label }}
              </button>
            </div>

            <div class="relative w-full md:w-1/3">
              <input
                type="text"
                class="w-full py-1.5 pl-10 pr-4 bg-gray-100 border border-gray-100 rounded-lg focus:outline-none"
                :placeholder="$t('knowledge_asset_finder.placeholder_search_files')"
                v-model="searchQuery" />
              <font-awesome-icon
                :icon="['fas', 'search']"
                class="absolute top-2 left-3 w-5 h-5 text-gray-500" />
            </div>
          </div>
        </div>

        <div class="w-full px-4">
          <div class="flex justify-between items-center mb-3">
            <h3 class="text-lg font-semibold text-gray-600">
              {{ $t("knowledge_asset_finder.my_files") }}
            </h3>
            <button @click="refreshFiles" class="text-sm py-1 px-4 rounded-lg">
              <font-awesome-icon :icon="['fas', 'sync']" class="mr-1" />
              {{ $t("knowledge_asset_finder.refresh") }}
            </button>
          </div>

          <div class="w-full overflow-x-auto text-gray-900">
            <table class="min-w-full text-start text-sm">
              <thead class="bg-gray-100">
                <tr>
                  <th @click="sort('name')" class="px-4 py-3 font-normal cursor-pointer text-left">
                    {{ $t("knowledge_asset_finder.file_name") }}
                    <font-awesome-icon :icon="sortIcon('name')" class="ml-2" />
                  </th>
                  <th @click="sort('number_of_pages')" class="px-4 py-3 font-normal cursor-pointer">
                    {{ $t("knowledge_asset_finder.number_of_pages") }}
                    <font-awesome-icon :icon="sortIcon('number_of_pages')" class="ml-2" />
                  </th>
                  <th
                    @click="sort('number_of_chunks')"
                    class="px-4 py-3 font-normal cursor-pointer">
                    {{ $t("knowledge_asset_finder.number_of_chunks") }}
                    <font-awesome-icon :icon="sortIcon('number_of_chunks')" class="ml-2" />
                  </th>
                  <th class="px-4 py-3 font-normal cursor-pointer">
                    {{ $t("knowledge_asset_finder.finished_processing") }}
                  </th>
                  <th class="px-4 py-3 font-normal text-center">
                    {{ $t("knowledge_asset_finder.action") }}
                  </th>
                </tr>
              </thead>
              <tbody class="text-gray-800">
                <tr v-if="isLoading" class="text-center">
                  <td colspan="4" class="py-5">
                    <font-awesome-icon :icon="['fas', 'spinner']" class="animate-spin text-xl" />
                    {{ $t("knowledge_asset_finder.refreshing_files") }}
                  </td>
                </tr>
                <tr
                  v-for="file in paginatedFiles"
                  :key="file.name"
                  class="border-b border-gray-200">
                  <td
                    class="px-4 py-3 font-medium cursor-pointer"
                    @click.prevent="
                      openDocument(
                        {
                          title: file.name,
                          showDetails: false,
                          chunks: [],
                          type: 'application/pdf',
                        },
                        file.hash,
                        1
                      )
                    ">
                    <font-awesome-icon :icon="file.icon" class="mr-2" />
                    {{ file.name }}
                  </td>
                  <td class="px-4 py-3 font-medium text-center">{{ file.number_of_pages }}</td>
                  <td class="px-4 py-3 font-medium text-center">{{ file.number_of_chunks }}</td>
                  <td class="px-4 py-3 font-medium text-center">
                    <font-awesome-icon v-if="file.processing_finished" :icon="['fas', 'check']" />
                    <font-awesome-icon
                      v-else
                      :icon="['fas', 'spinner']"
                      class="animate-spin text-xl" />
                  </td>
                  <td class="px-4 py-3 font-medium text-center">
                    <a
                      href="javascript:;"
                      class="inline-block mr-2 hover:text-red-500"
                      @click="deleteFile(file.hash)"
                      :title="$t('knowledge_asset_finder.delete')">
                      <font-awesome-icon :icon="['fas', 'trash']" />
                    </a>
                    <a
                      href="javascript:;"
                      class="inline-block hover:text-green-500"
                      @click="downloadFile(file.name, file.hash)"
                      :title="$t('knowledge_asset_finder.download')">
                      <font-awesome-icon :icon="['fas', 'download']" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="flex justify-between items-center mt-4">
            <div class="text-sm">
              {{ $t("knowledge_asset_finder.showing") }} {{ startIndex + 1 }}-{{ endIndex }}
              {{ $t("knowledge_asset_finder.of") }} {{ filteredFiles.length }}
            </div>
            <div class="flex items-center">
              <button
                @click="previousPage"
                :disabled="currentPage === 1"
                class="px-2 py-1 mr-2 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50">
                ←
              </button>
              <button
                @click="nextPage"
                :disabled="endIndex >= filteredFiles.length"
                class="px-2 py-1 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50">
                →
              </button>
              <label for="pageSize" class="ml-4 text-sm"
                >{{ $t("knowledge_asset_finder.page_size") }}:</label
              >
              <select v-model="pageSize" id="pageSize" class="ml-2 p-1 border rounded">
                <option v-for="size in [5, 20, 50]" :key="size" :value="size">{{ size }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { defineComponent, ref } from "vue";
import authenticatedAxios from "@/helpers/axios-wrapper";
import getEndpoint from "@/helpers/endpoints";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useToast } from "vue-toastification";
import {
  faSearch,
  faPlus,
  faTrash,
  faDownload,
  faSync,
  faSpinner,
  faSortUp,
  faSortDown,
  faSort,
} from "@fortawesome/free-solid-svg-icons";

import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import "filepond/dist/filepond.min.css";
import { useI18n } from "vue-i18n";

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default defineComponent({
  name: "KnowledgeAssetFinder",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    FilePond,
  },
  setup() {
    const icons = {
      search: faSearch,
      plus: faPlus,
      trash: faTrash,
      download: faDownload,
      sync: faSync,
      spinner: faSpinner,
      sortUp: faSortUp,
      sortDown: faSortDown,
      sort: faSort,
    };
    const { t } = useI18n();
    const searchQuery = ref("");
    const currentFilter = ref("all");
    const sortColumn = ref("name");
    const sortOrder = ref("asc");
    const pageSize = ref(5);
    const currentPage = ref(1);
    const isLoading = ref(false);
    const myFiles = ref([]);

    const sort = (column) => {
      if (sortColumn.value === column) {
        sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
      } else {
        sortColumn.value = column;
        sortOrder.value = "asc";
      }
    };

    const sortIcon = (column) => {
      if (sortColumn.value === column) {
        return sortOrder.value === "asc" ? icons.sortUp : icons.sortDown;
      }
      return icons.sort;
    };

    return {
      icons,
      searchQuery,
      currentFilter,
      sortColumn,
      sortOrder,
      pageSize,
      currentPage,
      sort,
      sortIcon,
      myFiles,
      isLoading,
      t,
    };
  },
  data() {
    return {
      serverConfig: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const formData = new FormData();
          formData.append(fieldName, file, file.name);

          const controller = new AbortController();

          authenticatedAxios
            .post(getEndpoint("/api/personal_knowledge_asset/file"), formData, {
              onUploadProgress: (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
              },
              timeout: 120000,
              headers: {
                "Content-Type": "multipart/form-data",
              },
              signal: controller.signal,
            })
            .then((response) => {
              load(response.data.key);
              this.refreshFiles();

              if (response.data.layout_parsing_finished === false) {
                authenticatedAxios
                  .patch(
                    getEndpoint(`/api/personal_knowledge_asset/file/${response.data.key}`),
                    { data: "something" },
                    {
                      timeout: 120000,
                    }
                  )
                  .then((response) => {
                    load(response.data.key);
                    this.refreshFiles();
                  });
              }
            })
            .catch((err) => {
              if (err.response) {
                // Server responded with a status code outside the 2xx range
                error(`Upload failed: ${err.response.statusText} (${err.response.status})`);
              } else if (err.request) {
                // No response was received
                error("Upload failed: No response from server.");
              } else {
                // Something else caused the error
                error(`Upload failed: ${err.message}`);
              }
              abort(); // Abort the upload process in FilePond
            });

          return {
            abort: () => {
              controller.abort();
              abort();
            },
          };
        },
        revert: (uniqueFileId, load) => {
          const controller = new AbortController();
          authenticatedAxios
            .delete(getEndpoint(`/api/personal_knowledge_asset/file/${uniqueFileId}`), {
              timeout: 120000,
              signal: controller.signal,
            })
            .then(() => {
              load();
              this.refreshFiles();
            });

          return {
            abort: () => {
              controller.abort();
            },
          };
        },
        load: (source, load, error, progress, abort) => {
          const controller = new AbortController();

          authenticatedAxios
            .get(getEndpoint(`/api/personal_knowledge_asset/file/${source}`), {
              timeout: 120000,
              responseType: "blob",
              signal: controller.signal,
            })
            .then((response) => {
              load(response.data);
            });

          return {
            abort: () => {
              controller.abort();
              abort();
            },
          };
        },
        remove: () => {
          // leaving this here for now.
          // Remove never seems to be triggered, our interface defaults to revert
          console.error("remove not implemented");
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    ...mapGetters("availableKnowledgeAssets", ["availableKnowledgeAssets"]),
    assetName() {
      return this.$route.params.assetName;
    },
    assetLabel() {
      const asset = this.availableKnowledgeAssets("personal-asset").find(
        (asset) => asset.name === this.assetName
      );
      return asset ? asset.label[this.$i18n.locale] : this.assetName;
    },
    filters() {
      const { t } = useI18n();
      return [
        { label: t("knowledge_asset_finder.filter_all"), type: "all", icon: "file" },
        { label: t("knowledge_asset_finder.filter_image"), type: "image", icon: "file-image" },
        { label: t("knowledge_asset_finder.filter_music"), type: "music", icon: "file-audio" },
        {
          label: t("knowledge_asset_finder.filter_documents"),
          type: "documents",
          icon: "file-pdf",
        },
        { label: t("knowledge_asset_finder.filter_others"), type: "others", icon: "file" },
      ];
    },
    filteredFiles() {
      let filtered = this.myFiles;

      if (filtered == undefined || filtered == null || filtered.length === 0) {
        return [];
      }

      if (this.currentFilter !== "all") {
        if (this.currentFilter === "documents") {
          const documentTypes = ["application/pdf"];
          filtered = filtered.filter((file) => documentTypes.includes(file.type));
        } else {
          filtered = filtered.filter((file) => file.type === this.currentFilter);
        }
      }
      if (this.searchQuery) {
        filtered = filtered.filter((file) =>
          file.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      filtered.sort((a, b) => {
        if (this.sortColumn === "name") {
          return this.sortOrder === "asc"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        } else if (this.sortColumn === "number_of_pages") {
          return this.sortOrder === "asc"
            ? (a.number_of_pages === "-" ? 0 : a.number_of_pages) -
                (b.number_of_pages === "-" ? 0 : b.number_of_pages)
            : (b.number_of_pages === "-" ? 0 : b.number_of_pages) -
                (a.number_of_pages === "-" ? 0 : a.number_of_pages);
        } else if (this.sortColumn === "number_of_chunks") {
          return this.sortOrder === "asc"
            ? (a.number_of_chunks === "-" ? 0 : a.number_of_chunks) -
                (b.number_of_chunks === "-" ? 0 : b.number_of_chunks)
            : (b.number_of_chunks === "-" ? 0 : b.number_of_chunks) -
                (a.number_of_chunks === "-" ? 0 : a.number_of_chunks);
        }
        return 0;
      });

      return filtered;
    },
    paginatedFiles() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredFiles.slice(start, end);
    },
    startIndex() {
      return (this.currentPage - 1) * this.pageSize;
    },
    endIndex() {
      return Math.min(this.startIndex + this.pageSize, this.filteredFiles.length);
    },
  },
  methods: {
    ...mapMutations({
      setDisplayPage: "app/setDisplayPage",
      setDisplayDocument: "app/setDisplayDocument",
    }),
    async openDocument(document, hash, page = 1) {
      document.pdfInput = await authenticatedAxios.get(
        getEndpoint(`/api/personal_knowledge_asset/file/${hash}`),
        {
          timeout: 120000,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      this.setDisplayDocument(document);
      this.setDisplayPage(page);
    },
    currentRouteName() {
      return this.$route.name;
    },
    async refreshFiles() {
      this.isLoading = true;
      try {
        const response = await authenticatedAxios.get(
          getEndpoint("/api/personal_knowledge_asset/file"),
          {
            timeout: 120000,
          }
        );
        this.myFiles = response.data.map((documentInformation) => ({
          name: documentInformation.title,
          hash: documentInformation.hash,
          number_of_pages: documentInformation.number_of_pages,
          number_of_chunks: documentInformation.number_of_chunks,
          processing_finished: documentInformation.layout_parsing_finished,
          icon: "file",
        }));
      } catch (error) {
        console.error("Error fetching files:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteFile(fileHash) {
      const toast = useToast();
      try {
        await authenticatedAxios.delete(
          getEndpoint(`/api/personal_knowledge_asset/file/${fileHash}`),
          {
            timeout: 120000,
          }
        );
        toast.success(this.$t("knowledge_asset_finder.delete_success", { filename: "" }));
        this.refreshFiles();
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    },
    async downloadFile(fileName, fileHash) {
      authenticatedAxios
        .get(getEndpoint(`/api/personal_knowledge_asset/file/${fileHash}`), {
          timeout: 120000,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          const linkSource = data;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.endIndex < this.filteredFiles.length) {
        this.currentPage++;
      }
    },
  },
  mounted() {
    this.refreshFiles();
  },
});
</script>

<style>
.filepond--panel-root {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.filepond--panel-root {
  background-color: transparent;
  border: 2px dashed rgb(209 213 219);
}
</style>
