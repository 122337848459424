import authenticatedAxios from "@/helpers/axios-wrapper";
import getEndpoint from "@/helpers/endpoints";
import { KnowledgeAsset } from "@/store/types/KnowledgeAssetTypes";
import { Module } from "vuex";
import config from "@/customer_configs_flags/config";

interface KnowledgeAssetsState {
  availableKnowledgeAssets: { [useCase: string]: KnowledgeAsset[] };
  selectedKnowledgeAssets: { [useCase: string]: KnowledgeAsset[] };
  areKnowledgeAssetsLoading: boolean;
  didErrorOccur: boolean;
}

const state: KnowledgeAssetsState = {
  availableKnowledgeAssets: {},
  selectedKnowledgeAssets: {},
  areKnowledgeAssetsLoading: true,
  didErrorOccur: false,
};

function snakeToCamel(str) {
  return str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
}

function convertKeysToCamelCase(obj) {
  const convertedObject = {};
  Object.keys(obj).forEach((key) => {
    convertedObject[snakeToCamel(key)] = obj[key];
  });
  return convertedObject;
}

const mutations = {
  setAreKnowledgeAssetsLoading(state: KnowledgeAssetsState, loading: boolean): void {
    state.areKnowledgeAssetsLoading = loading;
  },
  setAvailableKnowledgeAssets(
    state: KnowledgeAssetsState,
    { useCase, assets }: { useCase: string; assets: KnowledgeAsset[] }
  ) {
    state.availableKnowledgeAssets[useCase] = assets;
  },
  setAllAvailableKnowledgeAssets(
    state: KnowledgeAssetsState,
    assets: { [useCase: string]: KnowledgeAsset[] }
  ) {
    state.availableKnowledgeAssets = assets;
  },
  setSelectedKnowledgeAssets(
    state: KnowledgeAssetsState,
    { useCase, type, asset }: { useCase: string; type: string; asset?: KnowledgeAsset }
  ) {
    if (!state.selectedKnowledgeAssets[useCase]) {
      state.selectedKnowledgeAssets[useCase] = [];
    }
    const selectedAssets = state.selectedKnowledgeAssets[useCase];
    const availableAssets = state.availableKnowledgeAssets[useCase] || [];

    if (type === "selectAll") {
      selectedAssets.splice(0, selectedAssets.length, ...availableAssets);
    } else if (type === "deselectAll") {
      selectedAssets.splice(0, selectedAssets.length);
    } else if (type === "toggle" && asset) {
      const index = selectedAssets.findIndex((a) => a.name === asset.name);
      if (index > -1) {
        selectedAssets.splice(index, 1);
      } else {
        selectedAssets.push(asset);
      }
    } else if (type === "default") {
      selectedAssets.splice(
        0,
        selectedAssets.length,
        ...availableAssets.filter((a) => a.default === undefined || a.default)
      );
    }
  },
  setKnowledgeAssetFetchingError(state: KnowledgeAssetsState, { value }: { value: boolean }) {
    state.didErrorOccur = value;
  },
};

const getters = {
  availableKnowledgeAssets:
    (state: KnowledgeAssetsState) =>
    (useCase: string): KnowledgeAsset[] => {
      return state.availableKnowledgeAssets[useCase] || [];
    },
  selectedKnowledgeAssets:
    (state: KnowledgeAssetsState) =>
    (useCase: string): KnowledgeAsset[] => {
      return state.selectedKnowledgeAssets[useCase] || [];
    },
  allAvailableKnowledgeAssets(state: KnowledgeAssetsState): {
    [useCase: string]: KnowledgeAsset[];
  } {
    return state.availableKnowledgeAssets;
  },
  allSelectedKnowledgeAssets(state: KnowledgeAssetsState): {
    [useCase: string]: KnowledgeAsset[];
  } {
    return state.selectedKnowledgeAssets;
  },
  areKnowledgeAssetsLoading(state: KnowledgeAssetsState): boolean {
    return state.areKnowledgeAssetsLoading;
  },
  didErrorOccur(state: KnowledgeAssetsState): boolean {
    return state.didErrorOccur;
  },
};

const actions = {
  async fetchAvailableKnowledgeAssets({ commit, rootGetters }) {
    commit("setAreKnowledgeAssetsLoading", true);
    try {
      const { data } = await authenticatedAxios.get(getEndpoint("/api/core/knowledge_assets"), {
        timeout: 120000,
      });

      let convertedData: { [useCase: string]: KnowledgeAsset[] } = {};

      // Check whether the data is an array or an object
      if (Array.isArray(data)) {
        convertedData[config.useCases[0].name] = data.map(
          (item: any) => convertKeysToCamelCase(item) as KnowledgeAsset
        );
      } else if (typeof data === "object" && data !== null) {
        convertedData = Object.keys(data).reduce((acc, useCase) => {
          acc[useCase] = data[useCase].map(
            (item: any) => convertKeysToCamelCase(item) as KnowledgeAsset
          );
          return acc;
        }, {} as { [useCase: string]: KnowledgeAsset[] });
      } else {
        console.warn("Unexpected data format received:", data);
        commit("setKnowledgeAssetFetchingError", { value: true });
      }
      commit("setAllAvailableKnowledgeAssets", convertedData);
      Object.keys(convertedData).forEach((useCase) => {
        const availableAssets = convertedData[useCase];

        if (availableAssets.length === 1) {
          commit("setSelectedKnowledgeAssets", {
            useCase,
            type: "toggle",
            asset: availableAssets[0],
          });
        } else {
          commit("setSelectedKnowledgeAssets", { useCase, type: "default" });
        }
      });
    } catch (e) {
      console.error("Error fetching available knowledge assets", e);
      commit("setKnowledgeAssetFetchingError", { value: true });
    } finally {
      commit("setAreKnowledgeAssetsLoading", false);
    }
  },
};

const availableKnowledgeAssets: Module<KnowledgeAssetsState, any> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default availableKnowledgeAssets;
