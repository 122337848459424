import {
  ChatSearchChat,
  ChatSearchState,
  ConversationWithKnowledgeAsset,
  Reference,
  UseCaseConfig,
  QuestionAnswerPair,
} from "@/store/types/ChatSearchTypes";
import {
  getChatSearchChats,
  getChatSearchCurrentChatIds,
  setChatSearchChats,
  setChatSearchCurrentChatIds,
  removeChatSearchChatsFromDB,
  removeChatSearchCurrentChatIdsFromDB,
} from "@/helpers/indexedDbHelper";
import {
  formatReferencesToDocument,
  groupDocumentsByTitle,
  formatToUniqueReferences,
  isValidChatSearchChat,
} from "@/helpers/chatSearchStoreHelpers";
import config from "@/customer_configs_flags/config";
import { KnowledgeAsset } from "@/store/types/KnowledgeAssetTypes";
import { Module } from "vuex";
import authenticatedAxios from "@/helpers/axios-wrapper";
import authenticatedEventSource from "@/helpers/authenticatedEventSource";
import checkAuth from "@/helpers/checkAuth";
import getEndpoint from "@/helpers/endpoints";
import i18n from "@/helpers/i18n_init";
import pause from "@/helpers/pause";
import { v4 } from "uuid";

const { t } = i18n.global;

const state = (useCases: UseCaseConfig[]): ChatSearchState => {
  const initialChats: { [useCase: string]: { [chatId: string]: ChatSearchChat } } = {};
  const currentChatId: { [useCase: string]: string | null } = {};
  const isBlockedObject: { [useCase: string]: boolean } = {};

  useCases.forEach((useCaseConfig) => {
    const useCaseName = useCaseConfig.name;
    const newChatId = v4();
    initialChats[useCaseName] = {
      [newChatId]: {
        id: newChatId,
        initialQuestion: "",
        conversationsWithKnowledgeAssets: {},
        currentlyAnsweringAll: false,
      },
    };
    currentChatId[useCaseName] = newChatId;
    isBlockedObject[useCaseName] = false;
  });

  return {
    chats: initialChats,
    currentChatId: currentChatId,
    isBlockedDueToUnauthorized: isBlockedObject,
  };
};

const getters = {
  isBlockedDueToUnauthorized: (state: ChatSearchState) => (useCase: string) =>
    state.isBlockedDueToUnauthorized[useCase],
  chats: (state: ChatSearchState): { [useCase: string]: { [chatId: string]: ChatSearchChat } } =>
    state.chats,
  chatsByUseCase:
    (state: ChatSearchState) =>
    (useCase: string): { [chatId: string]: ChatSearchChat } =>
      state.chats[useCase] || {},
  currentChatId:
    (state: ChatSearchState) =>
    (useCase: string): string => {
      return state.currentChatId[useCase];
    },
  getAllCurrentChatIds: (state: ChatSearchState): { [useCase: string]: string } => {
    return state.currentChatId;
  },
  currentChat:
    (state: ChatSearchState, getters) =>
    (useCase: string): ChatSearchChat | null => {
      const chatId = getters.currentChatId(useCase);
      return chatId ? state.chats[useCase][chatId] : null;
    },
  currentConversation:
    (state: ChatSearchState, getters) =>
    (useCase: string): { [key: string]: ConversationWithKnowledgeAsset } => {
      const currentChat = getters.currentChat(useCase);
      return currentChat ? currentChat.conversationsWithKnowledgeAssets ?? {} : {};
    },
  chatHeadings:
    (state: ChatSearchState) =>
    (useCase: string): { chatId: string; chatHeading: string }[] => {
      return Object.entries(state.chats[useCase]).map(([chatId, chat]) => {
        const question: string = chat.initialQuestion;
        let chatHeading: string;

        if (
          chat.conversationsWithKnowledgeAssets !== undefined &&
          Object.keys(chat.conversationsWithKnowledgeAssets).length > 0
        ) {
          const assetName = Object.keys(chat.conversationsWithKnowledgeAssets)[0];
          chatHeading = chat.conversationsWithKnowledgeAssets[assetName].conversation[0].question;
        } else if (question) {
          chatHeading = question.length > 200 ? question.substring(0, 200) + "..." : question;
        } else {
          chatHeading = t("chat-search.awaiting_input");
        }

        return { chatId, chatHeading };
      });
    },
  currentlyAnsweringAll:
    (state: ChatSearchState, getters) =>
    (useCase: string): boolean => {
      const chat = getters.currentChat(useCase);
      return chat ? chat.currentlyAnsweringAll : false;
    },
  currentInitialQuestion:
    (state: ChatSearchState, getters) =>
    (useCase: string): string => {
      const chat = getters.currentChat(useCase);
      return chat ? chat.initialQuestion : "";
    },
  getFollowUpQuestion:
    (state: ChatSearchState, getters) =>
    (knowledgeAssetName: string, useCase: string): string => {
      const chat = getters.currentChat(useCase);
      return chat
        ? chat.conversationsWithKnowledgeAssets[knowledgeAssetName]?.followUpQuestion || ""
        : "";
    },
  getFollowUpState:
    (state: ChatSearchState, getters) =>
    (knowledgeAssetName: string, useCase: string): string => {
      const chat = getters.currentChat(useCase);
      return chat
        ? chat.conversationsWithKnowledgeAssets[knowledgeAssetName]?.followUpState ||
            "nothing-clicked"
        : "nothing-clicked";
    },
  getVisibility:
    (state: ChatSearchState, getters) =>
    (knowledgeAssetName: string, useCase: string): boolean => {
      const chat = getters.currentChat(useCase);
      return chat
        ? chat.conversationsWithKnowledgeAssets[knowledgeAssetName]?.visibility || false
        : false;
    },
  stoppedKnowledgeAssets:
    (state: ChatSearchState, getters) =>
    (useCase: string): string[] => {
      const chat = getters.currentChat(useCase);
      const knowledgeAssetNames = Object.keys(chat.conversationsWithKnowledgeAssets);
      return knowledgeAssetNames.filter(
        (name) => chat.conversationsWithKnowledgeAssets[name].stopped
      );
    },
};

const mutations = {
  setUnauthorizedBlock(
    state: ChatSearchState,
    { useCase, isBlocked }: { useCase: string; isBlocked: boolean }
  ) {
    state.isBlockedDueToUnauthorized[useCase] = isBlocked;
  },
  setAllChats(
    state: ChatSearchState,
    { chats }: { chats: { [useCase: string]: { [chatId: string]: ChatSearchChat } } }
  ) {
    state.chats = chats;
  },
  setChatsForUseCase(
    state: ChatSearchState,
    { chats, useCase }: { chats: { [chatId: string]: ChatSearchChat }; useCase: string }
  ) {
    state.chats[useCase] = chats;
  },
  setCurrentChatId(
    state: ChatSearchState,
    { useCase, chatId }: { useCase: string; chatId: string }
  ) {
    state.currentChatId[useCase] = chatId;
  },
  setCurrentChatIndexFromCache(
    state: ChatSearchState,
    { currentChatId }: { currentChatId: { [useCase: string]: string } }
  ) {
    state.currentChatId = currentChatId;
  },
  clearChatSearchDataPerUseCase(state: ChatSearchState, useCase: string) {
    const newChatId = v4();
    state.chats[useCase] = {
      [newChatId]: {
        id: newChatId,
        initialQuestion: "",
        conversationsWithKnowledgeAssets: {},
        currentlyAnsweringAll: false,
      },
    };
    state.currentChatId[useCase] = newChatId;
  },
  async clearAllChatSearchData(state: ChatSearchState) {
    try {
      Object.keys(state.chats).forEach(async (useCase) => {
        this.commit("chatSearch/clearChatSearchDataPerUseCase", useCase);
      });
      await removeChatSearchChatsFromDB("chatSearchConversations");
      await removeChatSearchCurrentChatIdsFromDB("chatSearchCurrentChatIndex");
    } catch (error) {
      console.error("Error clearing data:", error);
    }
  },
  setInitialQuestion(
    state: ChatSearchState,
    { useCase, question, chatId }: { useCase: string; question: string; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    state.chats[useCase][id].initialQuestion = question;
  },
  setQuestion(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      question,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; question: string; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation: ConversationWithKnowledgeAsset =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      conversation.conversation[conversation.conversation.length - 1].question = question;
    }
  },
  setFollowUpQuestion(
    state: ChatSearchState,
    {
      useCase,
      followUpQuestion,
      knowledgeAssetName,
      chatId,
    }: { useCase: string; followUpQuestion: string; knowledgeAssetName: string; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName].followUpQuestion =
      followUpQuestion;
  },
  async setFollowUpState(
    state: ChatSearchState,
    {
      useCase,
      followUpState,
      knowledgeAssetName,
      chatId,
    }: {
      useCase: string;
      followUpState: "nothing-clicked" | "follow-up-question-clicked" | "generate-button-clicked";
      knowledgeAssetName: string;
      chatId?: string;
    }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName].followUpState =
      followUpState;
  },
  setVisibility(
    state: ChatSearchState,
    {
      useCase,
      visibility,
      knowledgeAssetName,
      chatId,
    }: { useCase: string; visibility: boolean; knowledgeAssetName: string; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName].visibility =
      visibility;
  },
  async appendAnswer(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      answer,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; answer: string; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      if (!conversation.lock || typeof conversation.lock.then !== "function") {
        conversation.lock = Promise.resolve();
      }

      conversation.lock = conversation.lock.then(async () => {
        for (let i = 0; i < answer.length; i++) {
          conversation.conversation[conversation.conversation.length - 1].answer += answer[i];
          await pause(5);
        }
      });

      await conversation.lock;
    }
  },
  setAnswer(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      answer,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; answer: string; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      conversation.conversation[conversation.conversation.length - 1].answer = answer;
    }
  },
  setReferences(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      references,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; references: Reference[]; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      conversation.conversation[conversation.conversation.length - 1].references = references;
    }
  },
  appendImagesOnLastMessage(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      images,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; images: any[]; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      const lastMessageImages =
        conversation.conversation[conversation.conversation.length - 1].images;
      if (!lastMessageImages) {
        conversation.conversation[conversation.conversation.length - 1].images = images;
      }
      conversation.conversation[conversation.conversation.length - 1].images.push(...images);
    }
  },
  setUniqueReferences(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      uniqueReferences,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; uniqueReferences: any[]; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      conversation.conversation[conversation.conversation.length - 1].uniqueReferences =
        uniqueReferences;
    }
  },
  setReformulatedQuestion(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      reformulatedQuestion,
      chatId,
    }: {
      useCase: string;
      knowledgeAssetName: string;
      reformulatedQuestion: string;
      chatId?: string;
    }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      conversation.conversation[conversation.conversation.length - 1].reformulatedQuestion =
        reformulatedQuestion;
    }
  },
  appendReformulatedQuestion(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      reformulatedQuestion,
      chatId,
    }: {
      useCase: string;
      knowledgeAssetName: string;
      reformulatedQuestion: string;
      chatId?: string;
    }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      conversation.conversation[conversation.conversation.length - 1].reformulatedQuestion +=
        reformulatedQuestion;
    }
  },
  setCurrentlyAnswering(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      currentlyAnswering,
      chatId,
    }: {
      useCase: string;
      knowledgeAssetName: string;
      currentlyAnswering: boolean;
      chatId?: string;
    }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      conversation.currentlyAnswering = currentlyAnswering;
    }
  },
  setCurrentlyAnsweringAll(
    state: ChatSearchState,
    {
      useCase,
      currentlyAnsweringAll,
      chatId,
    }: { useCase: string; currentlyAnsweringAll: boolean; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    state.chats[useCase][id].currentlyAnsweringAll = currentlyAnsweringAll;
  },
  resetAllCurrentlyAnswering(state: ChatSearchState) {
    Object.keys(state.chats).forEach((useCase) => {
      Object.values(state.chats[useCase]).forEach((chat) => {
        Object.keys(chat.conversationsWithKnowledgeAssets).forEach((knowledgeAssetName) => {
          chat.conversationsWithKnowledgeAssets[knowledgeAssetName].currentlyAnswering = false;
        });
        chat.currentlyAnsweringAll = false;
      });
    });
  },
  addNewChat(state: ChatSearchState, { useCase }: { useCase: string }) {
    try {
      const newChatId: string = v4();
      state.chats[useCase][newChatId] = {
        id: newChatId,
        initialQuestion: "",
        conversationsWithKnowledgeAssets: {},
        currentlyAnsweringAll: false,
      };
      state.currentChatId[useCase] = newChatId;
    } catch (error) {
      console.error("Error adding new chat:", error);
    }
  },
  addNewConversation(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAsset,
      startingVisibility,
      chatId,
    }: {
      useCase: string;
      knowledgeAsset: KnowledgeAsset;
      startingVisibility: boolean;
      chatId?: string;
    }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const newConversation: ConversationWithKnowledgeAsset = {
      id: v4(),
      conversation: [],
      knowledgeAsset: knowledgeAsset,
      currentlyAnswering: false,
      stopped: false,
      lock: null,
      visibility: startingVisibility ?? false,
      followUpQuestion: "",
      followUpState: "nothing-clicked",
      eventSource: undefined,
    };
    state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAsset.name] =
      newConversation;
  },
  addNewQuestionAnswerPair(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    const newQuestionAnswerPair: QuestionAnswerPair = {
      answer: "",
      references: [],
      uniqueReferences: [],
      reformulatedQuestion: "",
      question: "",
      errorMessage: "",
      images: [],
    };
    if (conversation) {
      conversation.conversation.push(newQuestionAnswerPair);
    } else {
      console.error("Error adding new question-answer pair: conversation not found");
    }
  },
  deleteChat(state: ChatSearchState, { useCase, chatId }: { useCase: string; chatId: string }) {
    delete state.chats[useCase][chatId];
  },
  setEventSource(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      eventSource,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; eventSource: EventSource; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation) {
      conversation.eventSource = eventSource;
    }
  },
  closeEventSource(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversation =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversation && conversation.eventSource) {
      conversation.eventSource.close();
      conversation.eventSource = undefined;
    }
  },
  closeAllEventSourcesInCurrentChat(state: ChatSearchState, { useCase }: { useCase: string }) {
    const conversations =
      state.chats[useCase][state.currentChatId[useCase]].conversationsWithKnowledgeAssets;
    Object.keys(conversations).forEach((knowledgeAssetName) => {
      if (conversations[knowledgeAssetName].eventSource) {
        conversations[knowledgeAssetName].eventSource.close();
        conversations[knowledgeAssetName].eventSource = undefined;
      }
    });
  },
  setErrorOnLastMessage(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      errorMessage,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; errorMessage: string; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversationLength =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName].conversation
        .length;
    state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName].conversation[
      conversationLength - 1
    ].errorMessage = errorMessage;
  },
  setKnowledgeAssetStopped(
    state: ChatSearchState,
    {
      useCase,
      knowledgeAssetName,
      stopped,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; stopped: boolean; chatId?: string }
  ) {
    const id: string = chatId ?? state.currentChatId[useCase];
    const conversationWithKnowledgeAsset: ConversationWithKnowledgeAsset =
      state.chats[useCase][id].conversationsWithKnowledgeAssets[knowledgeAssetName];
    if (conversationWithKnowledgeAsset) {
      conversationWithKnowledgeAsset.stopped = stopped;
    }
  },
};

async function createEventSource(
  state: ChatSearchState,
  commit: any,
  dispatch: any,
  url: string,
  useCase: string,
  knowledgeAssetName: string,
  chatId: string
): Promise<void> {
  const eventSource = await authenticatedEventSource.create(url);

  commit("setEventSource", { useCase, knowledgeAssetName, eventSource, chatId });
  eventSource.onopen = () => {
    if (state.chats[useCase][chatId].conversationsWithKnowledgeAssets[knowledgeAssetName].stopped) {
      dispatch("stopSingleGeneration", { knowledgeAssetName, chatId, useCase });
      dispatch("persist");
    }
  };

  return new Promise<void>((resolve, reject) => {
    eventSource.onmessage = async (event: MessageEvent) => {
      const parse = JSON.parse(event.data);
      const type = parse.type;
      const data = parse.data;

      switch (type) {
        case "REFORMULATED_QUESTION":
          commit("appendReformulatedQuestion", {
            useCase,
            knowledgeAssetName,
            reformulatedQuestion: data,
            chatId,
          });
          break;
        case "RERANKED_DOCUMENTS":
          await dispatch("setReferences", {
            useCase,
            knowledgeAssetName,
            references: data,
            chatId,
          });
          dispatch("fetchImages", { useCase, knowledgeAssetName, chatId });
          break;
        case "ANSWER":
          commit("appendAnswer", { useCase, knowledgeAssetName, answer: data, chatId });
          break;
        case "FINAL_RESULT":
          dispatch("setReferences", {
            useCase,
            knowledgeAssetName,
            references: data.reranked_documents,
            chatId,
          });
          commit("setReformulatedQuestion", {
            useCase,
            knowledgeAssetName,
            reformulatedQuestion: data.question,
            chatId,
          });
          dispatch("persist");
          break;
        case "FINISHED":
          commit("setCurrentlyAnswering", {
            useCase,
            knowledgeAssetName,
            currentlyAnswering: false,
            chatId,
          });
          eventSource.close();
          setTimeout(() => {
            dispatch("persist");
          }, 5000);
          resolve();
          break;
        default:
          break;
      }
    };

    eventSource.onerror = (event: MessageEvent) => {
      const errorMessage = event.data || "Unknown error";
      if (errorMessage.includes("401")) {
        eventSource.close();
        commit("setUnauthorizedBlock", { useCase, isBlocked: true });
        reject(new Error("Unauthorized access, stopping requests."));
        return;
      }
      commit("setCurrentlyAnswering", {
        useCase,
        knowledgeAssetName,
        currentlyAnswering: false,
        chatId,
      });
      commit("setErrorOnLastMessage", { useCase, knowledgeAssetName, errorMessage, chatId });
      dispatch("persist");
      eventSource.close();
      checkAuth(event.target);
      if (errorMessage === "Unknown error") {
        reject(new Error("EventSource encountered an error"));
      } else {
        resolve();
      }
    };
  });
}
const actions = {
  async search(
    { state, commit, dispatch, getters, rootGetters },
    {
      question,
      filters,
      locale,
      useCase,
    }: { question: string; filters: any; locale: string; useCase: string }
  ) {
    if (!question.length) {
      return;
    }

    let chatId: string = getters.currentChatId(useCase);

    if (Object.keys(getters.currentConversation(useCase)).length > 0) {
      dispatch("addNewChat", { useCase });
      chatId = getters.currentChatId(useCase);
      commit("setInitialQuestion", { useCase, question, chatId });
    }
    commit("setCurrentlyAnsweringAll", { useCase, currentlyAnsweringAll: true, chatId });

    const selectedLLM: string = rootGetters["availableLLMs/selectedLLM"];
    const selectedKnowledgeAssets: KnowledgeAsset[] =
      rootGetters["availableKnowledgeAssets/selectedKnowledgeAssets"](useCase);
    const startingVisibility: boolean = true;

    const searchPromises = selectedKnowledgeAssets.map(async (asset) => {
      const knowledgeAssetName = asset.name;
      commit("addNewConversation", {
        useCase,
        knowledgeAsset: asset,
        startingVisibility,
      });
      commit("addNewQuestionAnswerPair", { useCase, knowledgeAssetName, chatId });
      commit("setQuestion", { useCase, knowledgeAssetName, question, chatId });
      commit("setCurrentlyAnswering", {
        useCase,
        knowledgeAssetName,
        currentlyAnswering: true,
        chatId,
      });
      dispatch("persist");

      const params = new URLSearchParams();
      params.append(
        "data",
        JSON.stringify({
          query: question,
          filters: filters,
          llm: selectedLLM,
          chat_id: getters.currentConversation(useCase)[knowledgeAssetName].id,
          locale: locale,
          knowledge_asset_name: knowledgeAssetName,
        })
      );
      const url: string = getEndpoint(`${asset.endpointName}?${params.toString()}`);

      try {
        await createEventSource(state, commit, dispatch, url, useCase, knowledgeAssetName, chatId);
      } catch (error) {
        console.error("Error creating authenticated EventSource:", error);
        commit("setCurrentlyAnswering", {
          useCase,
          knowledgeAssetName,
          currentlyAnswering: false,
          chatId,
        });
        commit("setErrorOnLastMessage", { useCase, knowledgeAssetName, chatId });
        dispatch("persist");
        throw error;
      }
    });

    return Promise.all(searchPromises)
      .then(() => {
        commit("setCurrentlyAnsweringAll", { useCase, currentlyAnsweringAll: false, chatId });
        return true;
      })
      .catch((error) => {
        console.error("Error in processing all assets:", error);
        commit("setCurrentlyAnsweringAll", { useCase, currentlyAnsweringAll: false, chatId });
        return false;
      });
  },
  async searchFollowUpQuestion(
    { state, commit, dispatch, getters, rootGetters },
    {
      question,
      filters,
      knowledgeAssetName,
      locale,
      useCase,
    }: {
      question: string;
      filters: any;
      knowledgeAssetName: string;
      locale: string;
      useCase: string;
    }
  ) {
    if (!question.length) {
      return;
    }
    const chatId: string = getters.currentChatId(useCase);
    const selectedLLM: string = rootGetters["availableLLMs/selectedLLM"];

    commit("addNewQuestionAnswerPair", { useCase, knowledgeAssetName });
    commit("setQuestion", { useCase, knowledgeAssetName, question, chatId });
    commit("setCurrentlyAnswering", {
      useCase,
      knowledgeAssetName,
      currentlyAnswering: true,
      chatId,
    });
    dispatch("persist");

    const asset = rootGetters["availableKnowledgeAssets/availableKnowledgeAssets"](useCase).find(
      (a) => a.name === knowledgeAssetName
    );

    const params = new URLSearchParams();
    params.append(
      "data",
      JSON.stringify({
        query: question,
        filters: filters,
        llm: selectedLLM,
        chat_id: getters.currentConversation(useCase)[knowledgeAssetName].id,
        locale: locale,
        knowledge_asset_name: knowledgeAssetName,
      })
    );

    const url = getEndpoint(`${asset.endpointName}?${params.toString()}`);

    const searchPromise = createEventSource(
      state,
      commit,
      dispatch,
      url,
      useCase,
      knowledgeAssetName,
      chatId
    );
    searchPromise.catch((error) => {
      console.error("Error in processing asset:", error);
      commit("setErrorOnLastMessage", {
        useCase,
        knowledgeAssetName: knowledgeAssetName,
        errorMessage: "Unknown Error",
        chatId,
      });
      dispatch("persist");
    });
  },
  setReferences(
    { commit },
    {
      useCase,
      knowledgeAssetName,
      references,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; references: Reference[]; chatId: string }
  ) {
    commit("setReferences", {
      useCase,
      knowledgeAssetName,
      references: groupDocumentsByTitle(formatReferencesToDocument(references)),
      chatId,
    });
    commit("setUniqueReferences", {
      knowledgeAssetName,
      uniqueReferences: formatReferencesToDocument(formatToUniqueReferences(references)),
      chatId,
      useCase,
    });
  },
  async fetchImages(
    { getters, commit, dispatch },
    {
      knowledgeAssetName,
      useCase,
      chatId,
    }: { knowledgeAssetName: string; useCase: string; chatId?: string }
  ) {
    const id: string = chatId ?? getters.currentChatIndex(useCase);
    const conversation = getters.currentConversation(useCase)[knowledgeAssetName].conversation;
    const references = conversation[conversation.length - 1].references;
    for (const reference of references) {
      const pages = [];
      reference.chunks.forEach((chunk) => {
        const pagesToAdd = Object.values(chunk.metadata.page_span);
        pagesToAdd.forEach((pageToAdd) => {
          if (!pages.includes(pageToAdd)) {
            pages.push(pageToAdd);
          }
        });
      });

      const urlParams = new URLSearchParams();
      urlParams.append("filePath", reference.metadata.source_uri);
      urlParams.append("pages", pages.join(","));
      const url: string = getEndpoint("/api/core/page_assets?") + urlParams.toString();

      try {
        const { data } = await authenticatedAxios.get(url);
        await commit("appendImagesOnLastMessage", {
          useCase,
          knowledgeAssetName: knowledgeAssetName,
          images: data.images,
          chatId: id,
        });
      } catch (e) {
        console.error(e);
      }
    }
    await dispatch("persist");
  },
  async stopAllGenerations({ commit, getters }, { useCase }: { useCase: string }) {
    commit("closeAllEventSourcesInCurrentChat", { useCase });
    commit("setCurrentlyAnsweringAll", { useCase, currentlyAnsweringAll: false });
    Object.keys(getters.currentConversation(useCase)).forEach((knowledgeAssetName) => {
      commit("setKnowledgeAssetStopped", { useCase, knowledgeAssetName, stopped: true });
      commit("setCurrentlyAnswering", { useCase, knowledgeAssetName, currentlyAnswering: false });
    });
  },
  async stopSingleGeneration(
    { commit, getters, dispatch },
    {
      useCase,
      knowledgeAssetName,
      chatId,
    }: { useCase: string; knowledgeAssetName: string; chatId?: string }
  ) {
    const id: string = chatId ?? getters.currentChatIndex(useCase);
    commit("closeEventSource", { useCase, knowledgeAssetName, chatIndex: id });
    commit("setKnowledgeAssetStopped", {
      useCase,
      knowledgeAssetName,
      stopped: true,
      chatIndex: id,
    });
    commit("setCurrentlyAnswering", {
      useCase,
      knowledgeAssetName,
      currentlyAnswering: false,
      chatIndex: id,
    });
    commit("setCurrentlyAnsweringAll", { useCase, currentlyAnsweringAll: false, chatIndex: id });
    dispatch("persist");
  },
  async loadCache({ commit }) {
    try {
      const cachedConversation = await getChatSearchChats("chatSearchConversations");
      const cachedChatId = await getChatSearchCurrentChatIds("chatSearchCurrentChatIndex");

      const initialState = state(config.useCases);

      let chats: { [useCase: string]: { [chatId: string]: ChatSearchChat } } = initialState.chats;
      let currentChatId: { [useCase: string]: string } = initialState.currentChatId;

      if (cachedConversation) {
        try {
          const parsedChats = JSON.parse(cachedConversation);

          if (
            typeof parsedChats === "object" &&
            Object.keys(parsedChats).every(
              (useCase) =>
                typeof parsedChats[useCase] === "object" &&
                Object.keys(parsedChats[useCase]).every((chatId) =>
                  isValidChatSearchChat(parsedChats[useCase][chatId])
                )
            )
          ) {
            chats = parsedChats;
          } else {
            console.warn(
              "Warning: Cached chats are not valid. Removing invalid data from IndexedDB."
            );
            await removeChatSearchChatsFromDB("chatSearchConversations");
          }
        } catch (error) {
          console.error("Error parsing cached conversation:", error);
          await removeChatSearchChatsFromDB("chatSearchConversations");
        }
      }

      if (cachedChatId) {
        try {
          if (
            typeof cachedChatId === "object" &&
            Object.keys(cachedChatId).every((key) => typeof cachedChatId[key] === "string")
          ) {
            currentChatId = cachedChatId;
          } else {
            console.warn("Invalid cached chat ID format. Removing invalid data from IndexedDB.");
            await removeChatSearchCurrentChatIdsFromDB("chatSearchCurrentChatIndex");
          }
        } catch (error) {
          console.error("Error parsing cached chat ID:", error);
          await removeChatSearchCurrentChatIdsFromDB("chatSearchCurrentChatIndex");
        }
      }
      config.useCases.forEach((useCase) => {
        if (!chats[useCase.name]) {
          chats[useCase.name] = initialState.chats[useCase.name];
        }
        if (currentChatId[useCase.name] === undefined) {
          currentChatId[useCase.name] = initialState.currentChatId[useCase.name];
        }
      });

      commit("setAllChats", { chats });
      commit("setCurrentChatIndexFromCache", { currentChatId });
    } catch (error) {
      console.error("Error loading cache:", error);
      commit("clearAllChatSearchData");
    }
  },
  async initializeChatSearchState({ dispatch, commit }) {
    await dispatch("loadCache");
    commit("resetAllCurrentlyAnswering");
  },
  async addNewChat({ commit, dispatch }, { useCase }: { useCase: string }) {
    commit("addNewChat", { useCase });
    dispatch("persist");
  },
  async removeChatSearchInstance(
    { getters, commit, dispatch },
    { useCase, chatId }: { useCase: string; chatId: string }
  ) {
    try {
      const chat: ChatSearchChat = getters.chatsByUseCase(useCase)[chatId];
      if (chat) {
        Object.keys(chat.conversationsWithKnowledgeAssets).forEach((knowledgeAssetName) => {
          const conversation = chat.conversationsWithKnowledgeAssets[knowledgeAssetName];
          if (conversation.eventSource && typeof conversation.eventSource.close === "function") {
            conversation.eventSource.close();
            conversation.eventSource = undefined;
          }
        });
      }
      commit("deleteChat", { useCase, chatId });
      if (getters.currentChatId(useCase) === chatId) {
        const remainingChatIds = Object.keys(getters.chatsByUseCase(useCase));
        commit("setCurrentChatId", {
          useCase,
          chatId: remainingChatIds.length ? remainingChatIds[0] : null,
        });
      }
      dispatch("persist");
    } catch (error) {
      console.error("Error removing chat:", error);
    }
  },
  async setCurrentChatId(
    { commit, dispatch },
    { useCase, chatId }: { useCase: string; chatId: string }
  ) {
    commit("setCurrentChatId", { useCase, chatId });
    dispatch("persistId");
  },
  async persist({ dispatch }) {
    try {
      dispatch("persistChats");
      dispatch("persistId");
    } catch (error) {
      console.error("Error persisting state:", error);
    }
  },
  async persistChats({ getters }) {
    await setChatSearchChats("chatSearchConversations", JSON.stringify(getters.chats));
  },
  async persistId({ getters }) {
    try {
      await setChatSearchCurrentChatIds("chatSearchCurrentChatIndex", getters.getAllCurrentChatIds);
    } catch (error) {
      console.error("Failed to persist index", error);
    }
  },
};

const chatSearch: Module<ChatSearchState, any> = {
  namespaced: true,
  state: state(config.useCases),
  getters,
  actions,
  mutations,
};

export default chatSearch;
