import { v4 } from "uuid";

enum ChatMode {
  Document = "Document",
  Conversation = "Conversation",
}

interface Document {
  title: string;
  link: string;
  location: string;
  metadata: { [key: string]: string };
  chunks?: Array<{ [key: string]: string }>;
  relevanceScore?: number;
  showDetails: boolean;
  extractive_answers?: Object[];
}

interface Message {
  request: string;
  reply: string;
  feedback: { id: string; rating?: number; comment?: string };
  mode: ChatMode;
  llm: string;
  id: string;
  chatId: string;
  finishedLoading: boolean;
  documents?: Document[];
  generatedQuestion?: string; // refined prompt NOT IMPLEMENTED
  followQuestions?: string[];
  recognizedIntents?: string[];
  intentRecognitionDuration?: number;
  retrievalDuration?: number;
  stopped?: boolean;
  entry_id: string;
  lock: any;
  error: boolean;
}
interface ChatDocument {
  title: string;
  fileFormat: string;
  totalTokens: number;
  doneUploading: boolean;
  errorMessage: string;
  link: string;
  hash: string;
}

const initialChatDocument: ChatDocument = {
  title: "",
  fileFormat: "pdf",
  totalTokens: 0,
  doneUploading: false,
  errorMessage: "",
  link: "",
  hash: "",
};

interface Chat {
  id: string;
  llm: string;
  nextQuestion: string;
  mode: ChatMode;
  name: string;
  documents?: { [hash: string]: ChatDocument };
}

interface ChatState {
  currentChat: string;
  chats: { [key: string]: Chat };
  messages: { [key: string]: Message };
  showFullScreenFeedback: boolean;
  fullScreenFeedbackId: string;
  currentThumbsChoice: any;
  currentEntryId: string;
}

const newChatId = v4();
const newChat: Chat = {
  id: newChatId,
  name: "Ask me anything...",
  llm: undefined,
  nextQuestion: "",
  mode: ChatMode.Conversation,
  documents: {},
};

const initialState: ChatState = {
  currentChat: localStorage.currentChat ?? newChatId,
  chats: JSON.parse(localStorage.chats ?? "null") ?? {
    [newChatId]: { ...newChat, id: newChatId },
  },
  messages: JSON.parse(localStorage.messages ?? "null") ?? {},
  showFullScreenFeedback: false,
  fullScreenFeedbackId: null,
  currentThumbsChoice: null,
  currentEntryId: null,
};

export {
  ChatMode,
  Document,
  Message,
  Chat,
  ChatState,
  ChatDocument,
  initialState,
  newChat,
  initialChatDocument,
};
