import authenticatedAxios from "./axios-wrapper";
import getEndpoint from "./endpoints";

const clearDocumentData = async (): Promise<void> => {
  try {
    await authenticatedAxios.delete(getEndpoint("/api/personal_knowledge_asset/file"), {
      timeout: 120000,
    });
  } catch (error) {
    console.error("Error clearing document data", error);
  }
};

// Export the function
export default clearDocumentData;
