<template>
  <div id="layout-wrapper" class="flex flex-row h-full">
    <aside
      id="sidebar"
      class="z-20 fixed xl:relative top-0 left-0 bg-slate-100 w-[260px] h-full rounded-md transition-all duration-300 ease-in-out transform"
      :class="{
        'translate-x-[-100%]': !displaySidebar && isMobile,
        'translate-x-0': displaySidebar || !isMobile,
      }">
      <div class="flex w-full h-full">
        <div class="relative py-6 px-3 flex flex-col">
          <div class="flex-none">
            <div class="pl-5 mb-5">
              <a href="/">
                <img class="w-36" :src="getLogoPath()" />
              </a>
            </div>
            <div class="px-5 mb-5">
              <button
                type="button"
                @click="newQuestionButtonClick"
                class="group w-full flex items-center justify-between text-sm leading-6 text-slate-600 rounded-full border py-1.5 pl-4 pr-2 ring-1 ring-transparent transition duration-200 hover:ring-primary border-slate-300 bg-white">
                <span class="font-medium">{{ t("sidebar.ask") }}</span>
                <div class="flex items-center space-x-1 text-base">
                  <div
                    class="flex h-5 min-w-5 items-center justify-center rounded border px-1 font-mono border-slate-300 bg-transparent">
                    {{ isMac ? "⌘" : "Ctrl" }}
                  </div>
                  <div
                    class="flex h-5 w-5 items-center justify-center rounded border font-mono text-xs border-slate-300 bg-transparent">
                    {{ isMac ? "K" : "I" }}
                  </div>
                </div>
              </button>
            </div>
            <Navigation />
            <div v-if="areKnowledgeAssetsLoading">
              <LoadingSpinner />
            </div>
          </div>
          <div class="grow mt-5">
            <template v-for="useCase in featureFlags.useCases" :key="useCase.name">
              <CollapsibleSection
                title="Knowledge Assets"
                v-if="
                  currentRouteName === useCase.name &&
                  availableKnowledgeAssets(useCase.name).length > 1
                ">
                <KnowledgeAssetSelection :useCase="useCase.name" />
              </CollapsibleSection>
              <CollapsibleSection
                :title="t('sidebar.private_collection')"
                v-if="
                  useCase.name === 'personal-asset' && currentRouteName.startsWith('personal-asset')
                ">
                <KnowledgeAssetSelection :useCase="useCase.name" />
              </CollapsibleSection>
              <CollapsibleSection
                v-if="currentRouteName === useCase.name"
                :iconAction="() => addNewChat({ useCase: useCase.name })"
                :icon="['fas', 'plus']"
                :title="t('chat-search.search_history_title')">
                <ChatSearchHistory :useCase="useCase.name" ref="chatSearchHistory" />
              </CollapsibleSection>
            </template>

            <CollapsibleSection
              :title="t('chat_list.your_chats')"
              :iconAction="addChat"
              :icon="['fas', 'plus']"
              v-if="currentRouteName == 'chat'">
              <ChatList ref="chatList" />
            </CollapsibleSection>
          </div>
          <div class="flex-none px-8">
            <div class="flex justify-between items-center">
              <CustomMenu as="div" class="relative">
                <div class="pr-3">
                  <MenuButton>
                    <InitialAvatar :initials="initials" class="shadow-md" />
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems
                    class="absolute bottom-9 z-10 mb-2 w-56 origin-bottom-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <div
                          :class="[
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-3 text-sm cursor-pointer',
                          ]"
                          @click="this.clearAllData()">
                          <font-awesome-icon icon="fa-solid fa-trash" class="pr-5" />
                          {{ t("sidebar.delete_app_data") }}
                        </div>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <div
                          @click="signout()"
                          :class="[
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-3 text-sm cursor-pointer',
                          ]">
                          <font-awesome-icon icon="fa-solid fa-right-from-bracket" class="pr-5" />
                          Sign out
                        </div>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </CustomMenu>

              <a
                v-if="featureFlags.enableHelpButton"
                :href="
                  featureFlags.helpPageURL ??
                  'https://docs.google.com/document/d/e/2PACX-1vTAq6djSpJ4avoXZ3O5dn05mjcFhoKgNGtLWwfgjyY6bUELzF7A6nGrkQB7LYjIprVj5y8xBldbPzfB/pub'
                "
                class="rounded-full flex items-center justify-center hover:bg-primary-dark text-white w-8 h-8 font-bold bg-primary text-xs shadow-md hover:shadow-lg"
                target="_blank">
                <font-awesome-icon class="w-3 h-3" icon="fa-solid fa-question"></font-awesome-icon>
              </a>

              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </div>
      <button
        v-if="isMobile"
        class="absolute z-50 mt-6 top-5 transform -translate-y-1/2 right-[-20px] flex items-center justify-center rounded-full bg-slate-100 w-10 h-10"
        @click="setDisplaySidebar(!displaySidebar)">
        <font-awesome-icon
          :icon="['fas', displaySidebar ? 'chevron-left' : 'chevron-right']"
          class="text-lg cursor-pointer ml-3 hover:text-slate-500" />
      </button>
    </aside>

    <!-- Overlay -->
    <div
      v-if="displaySidebar && isMobile"
      class="fixed inset-0 bg-black opacity-50 z-10"
      @click="setDisplaySidebar(false)"></div>
  </div>
</template>

<script>
import CollapsibleSection from "./CollapsibleSection.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapMutations, mapGetters, mapActions } from "vuex";
import LoadingSpinner from "./LoadingSpinner.vue";
import Navigation from "./Navigation.vue";
import InitialAvatar from "./InitialAvatar.vue";
import ChatSearchHistory from "./ChatSearch/ChatSearchHistory.vue";
import { Menu as CustomMenu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import ChatList from "./Chat/ChatList.vue";
import KnowledgeAssetSelection from "./ChatSearch/KnowledgeAssetSelection.vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";
import config from "../customer_configs_flags/config.ts";
import { useI18n } from "vue-i18n";
import clearDocumentData from "@/helpers/knowledgeAssetDocuments";

export default {
  name: "SideBar",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    ChatSearchHistory,
    CollapsibleSection,
    "font-awesome-icon": FontAwesomeIcon,
    Navigation,
    InitialAvatar,
    CustomMenu,
    MenuButton,
    LoadingSpinner,
    MenuItem,
    MenuItems,
    ChatList,
    KnowledgeAssetSelection,
    LanguageSwitcher,
  },
  data() {
    return {
      featureFlags: config,
      isMobile: window.innerWidth <= 1279, // Initial Check
    };
  },
  computed: {
    ...mapGetters({
      initials: "auth/initials",
      availableKnowledgeAssets: "availableKnowledgeAssets/availableKnowledgeAssets",
      displaySidebar: "app/displaySidebar",
      areKnowledgeAssetsLoading: "availableKnowledgeAssets/areKnowledgeAssetsLoading",
    }),
    currentRouteName() {
      return this.$route.name;
    },
    isMac() {
      if (navigator.userAgentData) {
        return navigator.userAgentData.platform.toUpperCase().includes("MAC");
      } else {
        return navigator.userAgent.toUpperCase().includes("MAC");
      }
    },
  },
  methods: {
    ...mapMutations({
      showLoadingOverlay: "app/showLoadingOverlay",
      hideLoadingOverlay: "app/hideLoadingOverlay",
      loadChatHistory: "chat/loadChatHistory",
      setCurrentChat: "chat/setCurrentChat",
      deleteChat: "chat/deleteChat",
      clearAllChatSearchData: "chatSearch/clearAllChatSearchData",
      clearChatData: "chat/clearData",
      setDisplaySidebar: "app/setDisplaySidebar",
    }),
    ...mapActions({
      signout: "auth/signout",
      addChat: "chat/addChat",
      addNewChat: "chatSearch/addNewChat",
    }),
    newQuestionButtonClick() {
      const currentRouteName = this.$route.name;

      if (currentRouteName === "chat") {
        this.addChat();
      } else if (currentRouteName.endsWith("-KnowledgeAssetFinder")) {
        const useCaseName = currentRouteName.replace("-KnowledgeAssetFinder", "");
        const useCase = config.useCases.find((uc) => uc.name === useCaseName);

        if (useCase) {
          this.addNewChat({ useCase: useCaseName });
          this.$router.push(`/${useCase.route}`);
        }
      } else {
        this.addNewChat({ useCase: currentRouteName });
      }
    },
    getLogoPath() {
      return `/logo.${this.featureFlags.logoExtension}`;
    },
    async clearAllData() {
      if (confirm("All session data will be cleared. Proceed?")) {
        await this.showLoadingOverlay();
        await this.clearChatData();
        await this.clearAllChatSearchData();
        await clearDocumentData();
        await this.hideLoadingOverlay();
        localStorage.locale = this.$i18n.fallbackLocale;
        this.$router.go(0);
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 1279;
    },
    handleKeyDown(event) {
      if (
        (event.metaKey || event.ctrlKey) &&
        (event.key.toLowerCase() === "k" || event.key.toLowerCase() === "i")
      ) {
        event.preventDefault();
        if (this.currentRouteName === "chat") {
          this.addChat();
        } else {
          this.addNewChat({ useCase: this.currentRouteName });
        }
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("keydown", this.handleKeyDown);
    this.loadChatHistory();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("keydown", this.handleKeyDown);
  },
};
</script>

<style scoped>
#layout-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

#sidebar {
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1279px) {
  .translate-x-[-100%] {
    transform: translateX(-100%);
  }

  #layout-wrapper {
    flex-direction: column;
  }
}
</style>
