import { AllowedModelNames, LLMState, LanguageModel } from "@/store/types/LLMTypes";

import { Module } from "vuex";
// store/modules/llm.ts
import authenticatedAxios from "@/helpers/axios-wrapper";
import config from "@/customer_configs_flags/config";
import getEndpoint from "@/helpers/endpoints";

const defaultModelName: AllowedModelNames =
  config.defaultModelName ?? AllowedModelNames.GEMINI_1_5_PRO;

const state: LLMState = {
  availableLLMs: [],
  selectedLLMIndex: 0,
  areLLMsLoading: true,
};

const mutations = {
  setAvailableLLMs(state: LLMState, llms: LanguageModel[]) {
    state.availableLLMs = llms;

    // Check if the default model is in the available models
    const defaultModelExists = llms.some((llm) => llm.chat_model_name === defaultModelName);
    if (defaultModelExists) {
      const defaultModelIndex = llms.findIndex((llm) => llm.chat_model_name === defaultModelName);
      state.selectedLLMIndex = defaultModelIndex;
    } else {
      console.error(
        `Default model ${defaultModelName} is not available. Selecting the first available model.`
      );
      if (llms.length > 0) {
        state.selectedLLMIndex = 0;
      } else {
        console.error("No available LLMs fetched.");
      }
    }
  },
  setSelectedLLMIndex(state: LLMState, llm: LanguageModel) {
    state.selectedLLMIndex = state.availableLLMs.indexOf(llm);
  },
  setAreLLMsLoading(state: LLMState, loading: boolean) {
    state.areLLMsLoading = loading;
  },
};

const actions = {
  async fetchAvailableLLMs({ commit, rootGetters }) {
    commit("setAreLLMsLoading", true);
    try {
      const { data } = await authenticatedAxios.get(getEndpoint("/api/core/model_overview"), {
        timeout: 120000,
      });
      commit("setAvailableLLMs", data);
    } catch (e) {
      console.error("Error fetching available LLMs", e);
    } finally {
      commit("setAreLLMsLoading", false);
    }
  },
};

const getters = {
  availableLLMs: (state: LLMState) => state.availableLLMs,
  selectedLLM(state: LLMState) {
    return (
      state.availableLLMs[state.selectedLLMIndex]?.chat_model_name ??
      AllowedModelNames.GEMINI_1_5_FLASH
    );
  },
  selectedLLMIndex: (state: LLMState) => state.selectedLLMIndex,
  selectedLLMFullModel: (state: LLMState) => state.availableLLMs[state.selectedLLMIndex],
  areLLMsLoading: (state: LLMState) => state.areLLMsLoading,
};

const llmModule: Module<LLMState, any> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default llmModule;
