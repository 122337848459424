<template>
  <div class="flex flex-col justify-center items-center min-h-screen w-full" v-if="!firstQuestion">
    <h1 class="mb-5">{{ t("chat-search.selectKnowledge") }}</h1>
    <KnowledgeAssetCards :useCase="useCase" class="mb-8 px-8" />
    <div class="overflow-auto pb-10 px-8 w-full">
      <div class="rounded-3xl z-5">
        <div class="flex justify-center w-full">
          <div
            class="bg-cwhite flex items-center w-full max-w-screen-md rounded-full border-[15px] border-slate-200 pr-2">
            <font-awesome-icon
              icon="fa-solid fa-magnifying-glass"
              class="text-slate-400 my-auto h-4 w-4 pl-4" />
            <TextAreaWithResize
              tailwind-classes="pt-2 px-5 pr-3"
              id="questionInput"
              :placeholder="t('chat-search.input_placeholder')"
              v-model="searchQuestionInput"
              :enterkeyhint="t('chat-search.input_enter_key_hint')"
              @inputConfirmed="askInitialQuestion()" />
            <div
              v-if="currentlyAnsweringAll(useCase)"
              @click="stopAllGenerations({ useCase })"
              class="relative cursor-pointer group box-content p-2 rounded-full text-sm"
              v-tooltip.top="'Stop generation'">
              <div class="absolute inset-0 flex items-center justify-center">
                <div
                  class="w-7 h-7 bg-slate-200 hover:bg-red-300 rounded-full border-t-primary border-2 animate-spin transition"></div>
              </div>
              <div class="relative flex items-center justify-center w-full h-full">
                <FontAwesomeIcon
                  icon="fa-solid fa-stop"
                  class="w-3.5 h-3.5 text-slate-600 group-hover:text-cwhite" />
              </div>
            </div>
            <div
              v-else
              @click="askInitialQuestion()"
              class="flex items-center justify-center btn-lg text-cwhite px-1.5 py-1.5 rounded-full cursor-pointer bg-primary hover:bg-primary-dark"
              v-tooltip.top="'Ask question'">
              <font-awesome-icon class="w-4 h-4" icon="fa-solid fa-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="firstQuestion" class="overflow-auto w-full">
    <div class="overflow-auto pb-10 px-8 w-full">
      <div v-if="currentConversationForUseCase" class="flex flex-col space-y-6 mt-6">
        <!-- Display each conversation for each knowledge asset -->
        <span v-if="firstQuestion" class="font-extralight text-xl text-slate-800">
          {{ firstQuestion }}
        </span>
        <div
          v-for="(conversationItem, index) in Object.values(currentConversationForUseCase)"
          :key="index">
          <div id="knowledgeAssetTitleAndSpinner" class="flex items-center">
            <div class="flex items-center py-1 w-full space-x-4">
              <div>
                <span class="text-lg text-slate-500">
                  {{ conversationItem.knowledgeAsset.label[selectedLocale] }}
                </span>
              </div>
              <div class="flex">
                <button
                  @click="handleFileListOverview(conversationItem)"
                  v-tooltip="t('chat-search.references')"
                  class="rounded-full group w-10 h-10 flex flex-col items-center justify-center hover:bg-slate-200">
                  <font-awesome-icon :icon="['fas', 'database']" class="text-slate-700" />
                </button>
                <button
                  v-if="conversationItem.conversation.length > 1"
                  @click="toggleVisibility(conversationItem.knowledgeAsset.name)"
                  class="rounded-full group w-10 h-10 flex flex-col items-center justify-center hover:bg-slate-200"
                  v-tooltip="
                    visibility(conversationItem.knowledgeAsset.name).get()
                      ? t('shared.show_less')
                      : t('shared.show_all')
                  ">
                  <div class="relative flex flex-col items-center justify-center">
                    <font-awesome-icon
                      :icon="
                        visibility(conversationItem.knowledgeAsset.name).get()
                          ? 'fa-solid fa-chevron-down'
                          : 'fa-solid fa-chevron-up'
                      "
                      class="text-slate-700 text-xs" />

                    <font-awesome-icon
                      :icon="
                        visibility(conversationItem.knowledgeAsset.name).get()
                          ? 'fa-solid fa-chevron-up'
                          : 'fa-solid fa-chevron-down'
                      "
                      class="text-slate-700 text-xs" />
                  </div>
                </button>
              </div>
              <div
                v-if="isCurrentlyAnswering(conversationItem.knowledgeAsset.name)"
                @click="stopSingleGeneration(conversationItem.knowledgeAsset.name)"
                class="relative cursor-pointer group box-content p-2 rounded-full text-sm"
                v-tooltip.top="'Stop generation'">
                <div class="absolute inset-0 flex items-center justify-center">
                  <div
                    class="w-8 h-8 bg-slate-200 hover:bg-red-300 rounded-full border-t-primary border-2 animate-spin transition"></div>
                </div>
                <div class="relative flex items-center justify-center w-full h-full">
                  <FontAwesomeIcon
                    icon="fa-solid fa-stop"
                    class="w-4 h-4 text-slate-600 group-hover:text-cwhite" />
                </div>
              </div>
              <div v-if="isKnowledgeAssetStopped(conversationItem.knowledgeAsset.name)">
                <div class="text-sm items-center flex text-red-600">
                  <ExclamationTriangleIcon
                    class="h-8 w-8 p-2 mr-2 rounded-full bg-red-200 font-light" />
                  {{ t("chat-search.generation_stopped") }}
                </div>
              </div>
            </div>
            <div class="h-3 w-3 px-5"></div>
          </div>
          <div class="bg-cwhite rounded-md border px-8 my-2 py-6">
            <div class="ml-6">
              <div
                v-if="
                  !visibility(conversationItem.knowledgeAsset.name).get() &&
                  conversationItem.conversation.length > 1
                "
                class="text-center text-slate-500 cursor-pointer mb-4"
                @click="toggleVisibility(conversationItem.knowledgeAsset.name)">
                <span class="text-sm">
                  ~ {{ conversationItem.conversation.length - 1 }} {{ t("shared.more") }} ~
                </span>
              </div>
              <div id="genow-answers" class="flex flex-col-reverse rounded-xl mb-4">
                <div
                  v-for="(message, key) in visibility(conversationItem.knowledgeAsset.name).get()
                    ? conversationItem.conversation.slice().reverse()
                    : conversationItem.conversation.slice(-1)"
                  :key="key"
                  class="">
                  <div
                    class="pb-4 pt-8 border-t-2"
                    v-if="key < conversationItem.conversation.length - 1">
                    <div class="relative group" v-if="!message.errorMessage">
                      <SparklesIcon class="svg-inline--fa mr-1" />
                      <span class="font-light text-xl mb-3 text-slate-800 font-mono">
                        <b> {{ message.reformulatedQuestion }}</b>
                      </span>
                      <div
                        class="tooltip-content bg-cwhite border border-secondary-dark text-slate-600 text-sm px-4 py-3 rounded-lg shadow-md z-10 absolute top-full right-0 mt-2 hidden group-hover:block">
                        {{ t("chat-search.original_question") }} <b>{{ message.question }}</b>
                      </div>
                    </div>
                  </div>
                  <div
                    class="w-full mx-auto py-3"
                    :class="{
                      'bg-red-50 border border-red-200 ': message.errorMessage,
                      'bg-cwhite': !message.errorMessage,
                    }">
                    <div class="transition-container">
                      <transition name="fade" mode="out-in">
                        <div>
                          <div key="content" class="content">
                            <Markdown
                              v-if="message.answer"
                              :source="message.answer"
                              class="break-words markdown mb-3" />
                            <div
                              class="flex overflow-x-scroll pb-3 p-2"
                              v-if="message.images && message.images.length > 0">
                              <img
                                v-for="(image, key) in message.images"
                                :key="key"
                                :src="image"
                                class="h-16 cursor-pointer scale-75 hover:scale-100 inset-0 ring-1 ring-inset ring-black/10 rounded-lg"
                                @click="
                                  () => {
                                    setDisplayAsset(true);
                                    setAssetSource(image);
                                  }
                                " />
                            </div>
                            <div
                              v-if="message.errorMessage"
                              class="text-red-600 w-full justify-between flex px-3">
                              <div class="flex items-center">
                                <font-awesome-icon
                                  :icon="['fas', 'circle-exclamation']"
                                  class="mr-4" />
                                <div>
                                  {{ t("chat-search.error") }} {{ message.errorMessage }}.
                                  <br />
                                  {{ t("chat-search.reformulate") }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="py-2 block max-w-5xl skeleton"
                              v-else-if="
                                conversationItem.currentlyAnswering && message.answer.length === 0
                              "
                              key="skeleton"></div>
                            <div class="flex gap-4 mt-2 items-center">
                              <div class="flex flex-wrap gap-3">
                                <MiniDocumentCard
                                  v-for="(reference, key) in message.references"
                                  :key="key"
                                  :document="reference"
                                  @miniCardClick="openDocument(reference)" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex rounded-b-2xl justify-between space-x-2.5 px-4"
              :class="{
                'items-center':
                  followUpState(conversationItem.knowledgeAsset.name).get() !==
                  'generate-button-clicked',
                '': visibility(conversationItem.knowledgeAsset.name).get(),
              }">
              <div
                class="flex rounded-3xl transition-all"
                :class="{
                  'w-3/12':
                    followUpState(conversationItem.knowledgeAsset.name).get() === 'nothing-clicked',
                  'w-11/12':
                    followUpState(conversationItem.knowledgeAsset.name).get() ===
                    'follow-up-question-clicked',
                }"
                v-if="
                  followUpState(conversationItem.knowledgeAsset.name).get() !=
                  'generate-button-clicked'
                ">
                <div class="flex items-center w-full rounded-3xl bg-slate-100">
                  <!-- input for follow-up question -->
                  <TextAreaWithResize
                    tailwind-classes="pt-2 px-5 pr-10 rounded-full"
                    backgroundColor="bg-transparent"
                    id="followUpQuestionInput"
                    :placeholder="t('chat-search.ask_follow_up')"
                    v-bind:model-value="
                      followUpQuestion(conversationItem.knowledgeAsset.name).get()
                    "
                    @input="
                      followUpQuestion(conversationItem.knowledgeAsset.name).set(
                        $event.target.value
                      )
                    "
                    @reset="resetFollowUpState(conversationItem.knowledgeAsset.name)"
                    @focus.self="followUpQuestionClicked(conversationItem.knowledgeAsset.name)"
                    @focusout="resetFollowUpStateFocus(conversationItem.knowledgeAsset.name)"
                    @inputConfirmed="askFollowUpQuestion(conversationItem.knowledgeAsset.name)" />
                  <div
                    @click="askFollowUpQuestion(conversationItem.knowledgeAsset.name)"
                    class="btn btn-lg text-cwhite box-content px-3 py-2 w-5 h-6 rounded-l-md rounded-r-3xl transition cursor-pointer"
                    :class="{
                      'hover:bg-slate-100 cursor-pointer': conversationItem.currentlyAnswering,
                      'hover:bg-slate-100': !conversationItem.currentlyAnswering,
                    }"
                    :disabled="conversationItem.currentlyAnswering">
                    <font-awesome-icon
                      class="w-4 h-4 text-slate-400 hover:text-secondar-dark"
                      icon="fa-solid fa-reply" />
                  </div>
                </div>
              </div>
              <GenerateButton
                v-if="
                  followUpState(conversationItem.knowledgeAsset.name).get() !==
                  'follow-up-question-clicked'
                "
                :useCase="useCase"
                :state="followUpState(conversationItem.knowledgeAsset.name).get()"
                :knowledgeAssetName="conversationItem.knowledgeAsset.name"
                @resetState="resetFollowUpState(conversationItem.knowledgeAsset.name)"
                @generateButtonClicked="
                  generateButtonClicked(conversationItem.knowledgeAsset.name)
                " />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ChatSearchSidebar
    :class="{ flex: displayChatSearchSidebar, hidden: !displayChatSearchSidebar }"
    v-if="featureFlags.enableChatSearchSidebar" />
  <button
    class="flex flex-col items-center justify-center"
    v-if="featureFlags.enableChatSearchSidebar">
    <font-awesome-icon
      :icon="['fas', 'chevron-down']"
      v-if="!displayChatSearchSidebar"
      class="text-3xl rotate-90 cursor-pointer hover:text-slate-500"
      @click="setDisplayChatSearchSidebar(true)" />
  </button>
</template>

<script>
import ChatSearchSidebar from "../components/ChatSearch/ChatSearchSidebar.vue";
import KnowledgeAssetCards from "../components/ChatSearch/KnowledgeAssetCards.vue";
import config from "../customer_configs_flags/config.ts";
import debounce from "@/helpers/debounce";
import MiniDocumentCard from "../components/MiniDocumentCard.vue";
import GenerateButton from "@/components/GenerateButton.vue";
import Markdown from "vue3-markdown-it";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { SparklesIcon } from "@heroicons/vue/24/outline";
import TextAreaWithResize from "@/components/TextAreaWithResize.vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const DEBOUNCE_TIMEOUT = 250; // in ms, so it equals 0.25s

export default {
  name: "ChatSearch",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    ChatSearchSidebar,
    MiniDocumentCard,
    GenerateButton,
    KnowledgeAssetCards,
    Markdown,
    SparklesIcon,
    TextAreaWithResize,
    ExclamationTriangleIcon,
    FontAwesomeIcon,
  },
  props: {
    displayPDF: {
      type: Boolean,
      required: false,
      default: false,
    },
    useCase: {
      type: String,
      required: true,
      default: config.useCases[0].name,
    },
  },
  data() {
    return {
      searchQuestionInput: "",
      featureFlags: config,
      dots: "",
      isLoading: false,
      sortKeys: ["Datum", "Thema"],
      currentSortKey: null,
      currentSortDirection: null,
      showFilters: false,
      filters: {
        start_date: null,
        end_date: null,
        folder: null,
      },
      selectedLocale: this.$i18n.locale,
    };
  },
  computed: {
    ...mapGetters({
      currentConversation: "chatSearch/currentConversation",
      currentChat: "chatSearch/currentChat",
      currentlyAnsweringAll: "chatSearch/currentlyAnsweringAll",
      stoppedKnowledgeAssets: "chatSearch/stoppedKnowledgeAssets",
      getFollowUpQuestion: "chatSearch/getFollowUpQuestion",
      getFollowUpState: "chatSearch/getFollowUpState",
      getVisibility: "chatSearch/getVisibility",
      selectedKnowledgeAssets: "availableKnowledgeAssets/selectedKnowledgeAssets",
      displayChatSearchSidebar: "app/displayChatSearchSidebar",
    }),
    followUpQuestion() {
      return (knowledgeAssetName) => ({
        get: () => {
          return this.getFollowUpQuestion(knowledgeAssetName, this.useCase);
        },
        set: (value) => {
          this.$store.commit("chatSearch/setFollowUpQuestion", {
            useCase: this.useCase,
            followUpQuestion: value,
            knowledgeAssetName,
          });
        },
      });
    },
    followUpState() {
      return (knowledgeAssetName) => ({
        get: () =>
          this.$store.getters["chatSearch/getFollowUpState"](knowledgeAssetName, this.useCase),
        set: (value) =>
          this.$store.commit("chatSearch/setFollowUpState", {
            useCase: this.useCase,
            followUpState: value,
            knowledgeAssetName,
          }),
      });
    },
    visibility() {
      return (knowledgeAssetName) => ({
        get: () => this.getVisibility(knowledgeAssetName, this.useCase),
        set: (value) =>
          this.$store.commit("chatSearch/setVisibility", {
            useCase: this.useCase,
            visibility: value,
            knowledgeAssetName,
          }),
      });
    },
    isNotLocalhost() {
      return !window.location.hostname.includes("localhost");
    },
    currentMessage() {
      return (conversationItem) =>
        conversationItem.conversation[conversationItem.conversation.length - 1] || {
          answer: "",
          references: [],
        };
    },
    currentConversationForUseCase() {
      return this.currentConversation(this.useCase);
    },
    firstQuestion() {
      const conversation =
        this.currentConversationForUseCase[Object.keys(this.currentConversationForUseCase)[0]];
      return conversation?.conversation?.[0]?.question || "";
    },
    currentReferences() {
      return (conversationItem) =>
        conversationItem.conversation[conversationItem.conversation.length - 1]?.references ?? [];
    },
    isCurrentlyAnswering() {
      return (assetName) => {
        if (this.currentConversation(this.useCase)[assetName]) {
          return this.currentConversation(this.useCase)[assetName].currentlyAnswering;
        }
        return false;
      };
    },
  },
  methods: {
    ...mapActions({
      search: "chatSearch/search",
      searchFollowUpQuestion: "chatSearch/searchFollowUpQuestion",
      stopAllGenerations: "chatSearch/stopAllGenerations",
      stopSingleGeneration: "chatSearch/stopSingleGeneration",
    }),
    ...mapMutations({
      setDisplayPage: "app/setDisplayPage",
      setDisplayDocument: "app/setDisplayDocument",
      setDisplayFileList: "app/setDisplayFileList",
      setDisplayChatSearchSidebar: "app/setDisplayChatSearchSidebar",
      setDisplayAsset: "app/setDisplayAsset",
      setAssetSource: "app/setAssetSource",
      setFollowUpQuestion: "chatSearch/setFollowUpQuestion",
      setFollowUpState: "chatSearch/setFollowUpState",
      setVisibility: "chatSearch/setVisibility",
    }),
    checkForConversationLength(conversationItem) {
      return conversationItem.conversation?.length > 0;
    },
    showSuccessToast(message) {
      const toast = useToast();
      toast.success(message, {
        timeout: 5000,
        draggable: true,
      });
    },
    showWarningToast(message) {
      const toast = useToast();
      toast.warning(message, {
        timeout: 5000,
        draggable: true,
      });
    },
    askInitialQuestion: debounce(async function () {
      const maxCharacterLimit = 2500;

      if (this.searchQuestionInput.length > maxCharacterLimit) {
        this.showWarningToast(
          `Character limit exceeded. Max allowed is ${maxCharacterLimit} characters.`
        );
        return;
      }
      if (this.selectedKnowledgeAssets(this.useCase).length === 0) {
        this.showWarningToast("Please select a knowledge asset first!");
      } else {
        const question = this.searchQuestionInput;
        this.searchQuestionInput = "";
        await this.search({
          question: this.clearQuestion(question),
          filters: this.filters,
          locale: this.$i18n.locale,
          useCase: this.useCase,
        });
      }
    }, DEBOUNCE_TIMEOUT),
    askFollowUpQuestion: debounce(async function (knowledgeAssetName) {
      const maxCharacterLimit = 2500;
      const followUpQuestion = this.getFollowUpQuestion(knowledgeAssetName, this.useCase);

      if (followUpQuestion.length > maxCharacterLimit) {
        this.showWarningToast(
          `Character limit exceeded. Max allowed is ${maxCharacterLimit} characters.`
        );
        return;
      }

      await this.searchFollowUpQuestion({
        question: this.clearQuestion(followUpQuestion),
        filters: this.filters,
        knowledgeAssetName: knowledgeAssetName,
        locale: this.$i18n.locale,
        useCase: this.useCase,
      });

      this.setFollowUpQuestion({
        followUpQuestion: "",
        knowledgeAssetName: knowledgeAssetName,
        useCase: this.useCase,
      });
    }, DEBOUNCE_TIMEOUT),
    clearQuestion(question) {
      return question.replace(/"/g, "");
    },
    generateButtonClicked(knowledgeAssetName) {
      this.setFollowUpState({
        followUpState: "generate-button-clicked",
        knowledgeAssetName: knowledgeAssetName,
        useCase: this.useCase,
      });
    },
    followUpQuestionClicked(knowledgeAssetName) {
      this.setFollowUpState({
        followUpState: "follow-up-question-clicked",
        knowledgeAssetName: knowledgeAssetName,
        useCase: this.useCase,
      });
    },
    resetFollowUpStateFocus(knowledgeAssetName) {
      this.setFollowUpState({
        followUpState: "nothing-clicked",
        knowledgeAssetName: knowledgeAssetName,
        useCase: this.useCase,
      });
    },
    resetFollowUpState(knowledgeAssetName) {
      this.setFollowUpQuestion({
        followUpQuestion: "",
        knowledgeAssetName: knowledgeAssetName,
        useCase: this.useCase,
      });
      this.setFollowUpState({
        followUpState: "nothing-clicked",
        knowledgeAssetName: knowledgeAssetName,
        useCase: this.useCase,
      });
    },
    toggleVisibility(knowledgeAssetName) {
      const currentVisibility = this.getVisibility(knowledgeAssetName, this.useCase);
      this.setVisibility({
        visibility: !currentVisibility,
        knowledgeAssetName: knowledgeAssetName,
        useCase: this.useCase,
      });
    },
    openDocument(document, page = 1) {
      this.setDisplayDocument(document);
      this.setDisplayPage(page);
    },
    openLink(folder) {
      if (this.links && this.links[folder]) {
        window.open(this.links[folder], "_blank");
      }
    },
    formatChunk(chunk) {
      return chunk
        .replace("START_OF_TABLE_\nTABLE_IN_MARKDOWN:", "")
        .replace("START_OF_TABLE\nTABLE_IN_MARKDOWN:", "")
        .replace("_END_OF_TABLE_", "");
    },
    convertReferencesToLinks(text, references) {
      if (!references) {
        return text;
      }

      const updatedText = text.replace(/\[(\d+)\]/g, (match, n) => {
        const reference = references[n - 1];
        if (reference) {
          return `[[${n}]](${
            window.location.origin + window.location.pathname + "#" + encodeURI(reference)
          })`;
        }
        return match;
      });
      return updatedText;
    },
    navigateToAnchor(reference, knowledgeAssetName) {
      if (!this.visibility[knowledgeAssetName]) {
        this.visibility[knowledgeAssetName] = true;
      }
      this.$nextTick(() => {
        window.location.href =
          window.location.origin + window.location.pathname + "#" + reference.metadata.source_title;
      });
      this.$nextTick(() => {
        const elem = document.getElementById(reference.metadata.source_title);
        elem.classList.add("opacity-0", "transition-all", "duration-300");
        elem.classList.add(
          "opacity-90",
          "rounded-xl",
          "shadow-xl",
          "transform",
          "scale-105",
          "z-50"
        );
        setTimeout(() => {
          elem.classList.remove("rounded-xl", "shadow-xl", "transform", "scale-105");
        }, 400);
        setTimeout(() => {
          elem.classList.remove("z-50");
        }, 3000);
      });
    },
    triggerShowChunk(chunk) {
      chunk.showChunk = !chunk.showChunk;
    },
    formatPDFSearch(search) {
      search = search.split("...");
      return search.map((s) => s.replace(/&.{1,5};/gm, "").replace(/<[^>]*>?/gm, ""));
    },
    changeDisplayOfFilters() {
      this.showFilters = !this.showFilters;
    },
    handleFileListOverview(conversationItem) {
      this.setDisplayFileList(conversationItem.conversation);
    },
    resetFilters() {
      this.filters = {
        start_date: null,
        end_date: null,
        folder: null,
      };
    },
    getSortDirection(sortKey) {
      if (sortKey === this.currentSortKey) {
        return this.currentSortDirection;
      } else {
        return "descending";
      }
    },
    setCurrentSortKeyAndDirection(sortKey) {
      if (this.currentSortKey === sortKey) {
        if (this.currentSortDirection === "descending") {
          this.currentSortDirection = "ascending";
        } else {
          this.currentSortDirection = "descending";
        }
      } else {
        this.currentSortDirection = "descending";
      }
      this.currentSortKey = sortKey;
    },
    resetSorting() {
      this.currentSortKey = null;
      this.currentSortDirection = null;
    },
    isKnowledgeAssetStopped(assetName) {
      return this.stoppedKnowledgeAssets(this.useCase).includes(assetName);
    },
  },
  async mounted() {
    if (!this.firstQuestion) document.getElementById("questionInput").focus();
    setInterval(() => {
      const amountOfDots = (this.dots.length + 1) % 4;
      this.dots = ".".repeat(amountOfDots);
    }, 400);
    if (this.selectedKnowledgeAssets(this.useCase)) {
      this.selectedKnowledgeAssets(this.useCase).forEach((knowledgeAsset) => {
        this.followUpState[knowledgeAsset.name] = "nothing-clicked";
        this.visibility[knowledgeAsset.name] = false;
        this.visibility[knowledgeAsset.name] = false;
      });
    }
  },
};
</script>

<style scoped>
.tooltip-content {
  white-space: normal;
  word-wrap: break-word;
  position: absolute;
  top: 100%; /* Position below the button */
  left: 0;
  min-width: 10rem; /* Set a minimum width */
  max-width: 40vw; /* Increase the maximum width */
  z-index: 10;
  margin-top: 0.5rem; /* Add some space between button and tooltip */
}

.tooltip-right {
  white-space: normal;
  word-wrap: break-word;
  position: absolute;
  left: 100%; /* Position it to the right of the parent container */
  top: 0;
  min-width: 10rem; /* Set a minimum width */
  max-width: 40vw;
  max-height: 5rem;
  z-index: 10;
  margin-left: 0.5rem; /* Add some space between the parent container and the tooltip */
}

.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pastel-green {
  color: #9acd32;
}

.markdown li {
  list-style-type: circle;
}
</style>
