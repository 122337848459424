<template>
  <div>
    <div
      v-if="!areKnowledgeAssetsLoading && availableKnowledgeAssets(useCase).length > 0"
      class="grid gap-4 grid-flow-col auto-cols-auto">
      <div v-for="asset in availableKnowledgeAssets(useCase)" :key="asset.endpointName">
        <div
          @click="toggleSelection(asset)"
          :class="[
            'relative flex flex-col p-4 rounded-2xl border border-gray-200 shadow cursor-pointer hover:shadow-md',
            isSelected(asset.name) ? 'bg-gray-200' : '',
          ]">
          <div class="flex items-center justify-between">
            <FontAwesomeIcon :icon="['fas', 'database']" class="text-slate-700" />
            <div class="flex items-center">
              <FontAwesomeIcon
                v-if="useCase === 'personal-asset'"
                icon="gear"
                @click.stop.prevent="navigateToKnowledgeSettingsPage(asset.name)"
                class="cursor-pointer mr-2" />
              <input
                type="checkbox"
                :checked="isSelected(asset.name)"
                @click.stop="toggleSelection(asset)"
                class="form-checkbox" />
            </div>
          </div>
          <div class="mt-4">
            <div class="font-bold text-base">
              {{ asset.label[selectedLocale] }}
            </div>
            <div class="text-sm text-gray-600 mt-2">
              {{ asset.description[selectedLocale] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapMutations } from "vuex";
import config from "@/customer_configs_flags/config";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "KnowledgeAssetCards",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      selectedLocale: this.$i18n.locale,
    };
  },
  props: {
    useCase: {
      type: String,
      required: true,
      default: config.useCases[0].name,
    },
  },
  computed: {
    ...mapGetters("availableKnowledgeAssets", [
      "availableKnowledgeAssets",
      "selectedKnowledgeAssets",
      "areKnowledgeAssetsLoading",
    ]),
  },
  methods: {
    ...mapMutations("availableKnowledgeAssets", {
      setSelectedKnowledgeAssets: "setSelectedKnowledgeAssets",
    }),
    isSelected(assetName: string): boolean {
      return this.selectedKnowledgeAssets(this.useCase).some(
        (selectedAsset) => selectedAsset.name === assetName
      );
    },
    toggleSelection(asset) {
      this.setSelectedKnowledgeAssets({ type: "toggle", asset: asset, useCase: this.useCase });
    },
    navigateToKnowledgeSettingsPage(assetName) {
      const currentRouteName = this.$route.name;
      const useCaseName = currentRouteName.endsWith("-KnowledgeAssetFinder")
        ? currentRouteName.replace("-KnowledgeAssetFinder", "")
        : currentRouteName;

      const useCase = config.useCases.find((useCase) => useCase.name === useCaseName);

      if (useCase) {
        this.$router.push({
          name: `${useCase.name}-KnowledgeAssetFinder`,
          params: { assetName },
        });
      } else {
        console.error("Use-Case not found");
      }
    },
  },
};
</script>
