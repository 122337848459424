import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid gap-4 grid-flow-col auto-cols-auto"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = ["checked", "onClick"]
const _hoisted_6 = { class: "mt-4" }
const _hoisted_7 = { class: "font-bold text-base" }
const _hoisted_8 = { class: "text-sm text-gray-600 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.areKnowledgeAssetsLoading && _ctx.availableKnowledgeAssets($props.useCase).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableKnowledgeAssets($props.useCase), (asset) => {
            return (_openBlock(), _createElementBlock("div", {
              key: asset.endpointName
            }, [
              _createElementVNode("div", {
                onClick: ($event: any) => ($options.toggleSelection(asset)),
                class: _normalizeClass([
            'relative flex flex-col p-4 rounded-2xl border border-gray-200 shadow cursor-pointer hover:shadow-md',
            $options.isSelected(asset.name) ? 'bg-gray-200' : '',
          ])
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_FontAwesomeIcon, {
                    icon: ['fas', 'database'],
                    class: "text-slate-700"
                  }),
                  _createElementVNode("div", _hoisted_4, [
                    ($props.useCase === 'personal-asset')
                      ? (_openBlock(), _createBlock(_component_FontAwesomeIcon, {
                          key: 0,
                          icon: "gear",
                          onClick: _withModifiers(($event: any) => ($options.navigateToKnowledgeSettingsPage(asset.name)), ["stop","prevent"]),
                          class: "cursor-pointer mr-2"
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("input", {
                      type: "checkbox",
                      checked: $options.isSelected(asset.name),
                      onClick: _withModifiers(($event: any) => ($options.toggleSelection(asset)), ["stop"]),
                      class: "form-checkbox"
                    }, null, 8, _hoisted_5)
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(asset.label[$data.selectedLocale]), 1),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(asset.description[$data.selectedLocale]), 1)
                ])
              ], 10, _hoisted_2)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}