import {
  Chat,
  ChatDocument,
  ChatMode,
  ChatState,
  Document,
  Message,
  initialChatDocument,
  initialState,
} from "../types/ChatTypes";

import authenticatedAxios from "@/helpers/axios-wrapper";
import authenticatedEventSource from "@/helpers/authenticatedEventSource";
import getEndpoint from "@/helpers/endpoints";
import i18n from "@/helpers/i18n_init";
import pause from "@/helpers/pause";
import store from "..";
import { v4 } from "uuid";

const state: ChatState = { ...initialState };

const { t } = i18n.global;

const eventSources: {
  [key: string]: { event: EventSource; messageId: string };
} = {};

const getters = {
  chatHistory: (state) =>
    Object.values(state.messages).filter(
      (message: Message) => message.chatId === state.currentChat
    ),
  chatHistoryFromId: (state) => (chatId: string) =>
    Object.values(state.messages).filter((message: Message) => message.chatId === chatId),
  messages: (state) => Object.values(state.messages),
  chats: (state) => Object.values(state.chats),
  documentCounts: (state: ChatState) => {
    return Object.values(state.chats).map((chat) => Object.keys(chat.documents).length ?? 0);
  },
  chatDocuments: (state) => (chatId) => {
    return state.chats[chatId]?.documents ?? {};
  },
  currentChatDocuments: (state: ChatState) => {
    return state.chats[state.currentChat]?.documents ?? {};
  },
  currentChat: (state) => state.chats[state.currentChat],
  currentChatId: (state) => state.currentChat,
  lastMessage: (state) => {
    const messages: Message[] = Object.values(state.chats[state.currentChat]);
    const message = messages
      .reverse()
      .find((message: Message) => message.chatId === state.currentChat);
    return message;
  },
  currentChatLLM: (state) => state.chats[state.currentChat].llm,
  currentChatLength: (state) =>
    Object.values(state.messages).filter((message: Message) => message.chatId === state.currentChat)
      .length,
  nextQuestion: (state) => state.chats[state.currentChat].nextQuestion,
  mode: (state) => {
    return state.chats[state.currentChat].mode;
  },
  showFullScreenFeedback: (state) => state.showFullScreenFeedback,
  fullScreenFeedbackId: (state) => state.fullScreenFeedbackId,
  currentlyAnswering: (state) =>
    !Object.values(state.messages)
      .filter((m: Message) => m.chatId === state.currentChat)
      .reduce((acc, message: Message) => message.finishedLoading && acc, true),
  currentThumbsChoice: (state) => state.currentThumbsChoice,
  currentEntryId: (state) => state.currentEntryId,
};

const mutations = {
  loadChatHistory: (state: ChatState) => {
    state.chats = JSON.parse(localStorage.chats ?? "null") ?? state.chats;
    Object.values(state.chats).forEach((chat: Chat) => {
      if (!chat.documents || typeof chat.documents !== "object" || Array.isArray(chat.documents)) {
        chat.documents = {};
      }
    });
    state.messages = JSON.parse(localStorage.messages ?? "null") ?? state.messages;
    state.currentChat = localStorage.currentChat ?? state.currentChat;

    if (state.currentChat === null || state.chats[state.currentChat] === undefined) {
      state.currentChat = Object.keys(state.chats)[0];
    }

    localStorage.chats = JSON.stringify(state.chats);
    localStorage.messages = JSON.stringify(state.messages);
    localStorage.currentChat = state.currentChat;
  },
  addChatDocument(
    state: ChatState,
    { chatId, document }: { chatId: string; document: ChatDocument }
  ) {
    state.chats[chatId].documents[document.hash] = document;
  },
  updateChatDocument(
    state: ChatState,
    { chatId, hash, newDocument }: { chatId: string; hash: string; newDocument: ChatDocument }
  ) {
    state.chats[chatId].documents[hash] = newDocument;
  },
  removeChatDocument(state: ChatState, { chatId, hash }: { chatId: string; hash: string }) {
    delete state.chats[chatId].documents[hash];
  },
  setDocumentDoneUploading(
    state: ChatState,
    { document, doneUploading }: { document: ChatDocument; doneUploading: boolean }
  ) {
    document.doneUploading = doneUploading;
  },
  persist: (state: ChatState) => {
    localStorage.chats = JSON.stringify(state.chats);
    localStorage.messages = JSON.stringify(state.messages);
    localStorage.currentChat = state.currentChat;
  },
  setReply(
    state: ChatState,
    { reply, messageId, error }: { reply: string; messageId: string; error?: boolean }
  ) {
    const message = state.messages[messageId];
    message.error = !!error;

    if (error) message.finishedLoading = true;

    message.reply = reply;
    message.finishedLoading = true;
  },
  setFollowQuestions(
    state: ChatState,
    { messageId, questions }: { messageId: string; questions: string[] }
  ) {
    state.messages[messageId].followQuestions = questions;
  },
  setFinalResponse(state: ChatState, data: any) {
    state.messages[data.messageId].entry_id = data.evaluation.entry_id;
  },
  setDocuments(
    state: ChatState,
    {
      documents,
      messageId,
      duration,
    }: { documents: Document[]; messageId: string; duration?: number }
  ) {
    state.messages[messageId].documents = documents.map((doc) => {
      return { showDetails: false, ...doc };
    });
    state.messages[messageId].retrievalDuration = duration;
  },
  setRecognizedIntents(
    state: ChatState,
    {
      recognizedIntents,
      duration,
      messageId,
    }: { recognizedIntents: string[]; duration: number; messageId: string }
  ) {
    state.messages[messageId].recognizedIntents = recognizedIntents;
    state.messages[messageId].intentRecognitionDuration = duration;
  },
  showDocDetails: (state: ChatState, doc: Document) => {
    doc.showDetails = true;
  },
  hideDocDetails: (state: ChatState, doc: Document) => {
    doc.showDetails = false;
  },
  showFullScreenFeedback: (
    state: ChatState,
    { thumbsChoice, entryId }: { thumbsChoice: any; entryId: string }
  ) => {
    state.showFullScreenFeedback = true;
    state.currentEntryId = entryId;
    state.currentThumbsChoice = thumbsChoice;
  },
  hideFullScreenFeedback: (state: ChatState) => {
    state.showFullScreenFeedback = false;
    state.currentThumbsChoice = null;
  },
  setGeneratedQuestion(
    state: ChatState,
    { generatedQuestion, messageId }: { generatedQuestion: string; messageId: string }
  ) {
    state.messages[messageId].generatedQuestion = generatedQuestion;
  },
  setCurrentChat(state: ChatState, chatId: string) {
    state.currentChat = chatId;
    localStorage.currentChat = state.currentChat;
  },
  pushChat(state: ChatState, chat: Chat) {
    state.chats[chat.id] = chat;
    state.currentChat = chat.id;
    localStorage.chats = JSON.stringify(state.chats);
    localStorage.currentChat = JSON.stringify(state.currentChat);
  },
  addMessage(
    state: ChatState,
    {
      request,
      messageId,
      chatId,
      mode,
      llm,
    }: { request: string; messageId: string; chatId: string; mode: ChatMode; llm: string }
  ) {
    state.messages[messageId] = {
      request,
      reply: "",
      chatId,
      llm: llm,
      id: messageId,
      mode,
      finishedLoading: false,
      feedback: { id: messageId },
      entry_id: null,
      lock: null,
      error: false,
    };

    localStorage.messages = JSON.stringify(state.messages);
  },
  async addToMessage(state: ChatState, { messageId, token }: { messageId: string; token: string }) {
    if (!state.messages[messageId].lock) {
      state.messages[messageId].lock = Promise.resolve();
    }

    state.messages[messageId].lock = state.messages[messageId].lock.then(async () => {
      for (let i = 0; i < token.length; i++) {
        state.messages[messageId].reply += token[i];
        await pause(5);
      }
    });

    await state.messages[messageId].lock;
  },
  removeQuestion(state: ChatState, messageId: string) {
    delete state.messages[messageId];
    localStorage.messages = JSON.stringify(state.messages);
  },
  deleteChat(state: ChatState, chatId: string) {
    const chatIds = Object.keys(state.chats);
    const index = chatIds.indexOf(chatId);
    state.currentChat = chatIds[index - 1] ?? chatIds[index + 1] ?? chatIds[0];
    delete state.chats[chatId];

    Object.keys(state.messages).forEach((key) => {
      if (state.messages[key].chatId === chatId) {
        delete state.messages[key];
      }
    });
    localStorage.chats = JSON.stringify(state.chats);
    localStorage.messages = JSON.stringify(state.messages);
    localStorage.currentChat = state.currentChat;
  },
  setNextQuestion(state: ChatState, nextQuestion: string) {
    state.chats[state.currentChat].nextQuestion = nextQuestion;
  },
  resetNextQuestion(state: ChatState) {
    state.chats[state.currentChat].nextQuestion = "";
  },
  setMode(state: ChatState, mode: ChatMode) {
    state.chats[state.currentChat].mode = mode;
    localStorage.chats = JSON.stringify(state.chats);
  },
  setChatFeedback(
    state: ChatState,
    { messageId, feedback, data }: { messageId: string; feedback: any; data: any }
  ) {
    const chatToChange = state.messages[messageId];
    if (chatToChange) {
      chatToChange.feedback = feedback;
    }
  },
  clearData(state: ChatState) {
    state = { ...initialState };
    localStorage.removeItem("chats");
    localStorage.removeItem("messages");
    localStorage.removeItem("currentChat");
  },
  setFinishedLoading(
    state: ChatState,
    { messageId, value, stopped }: { messageId: string; value: boolean; stopped?: boolean }
  ) {
    state.messages[messageId].finishedLoading = value;
    state.messages[messageId].stopped = stopped;
  },
};
const actions = {
  /**
   * Orchestrates the process of asking a question by preparing the context, selecting the appropriate
   * processing mode based on configuration, and dispatching the question to the specified service.
   */
  async askQuestion(
    { state, commit, getters, dispatch, rootGetters },
    options?: { questionId?: string; question?: string; locale: string }
  ) {
    let messageId = v4();
    let nextQuestion = options?.question ?? getters.nextQuestion;
    let chatId = state.currentChat;
    let mode = state.chats[chatId].mode;
    let locale = "en";
    const llm = rootGetters["availableLLMs/selectedLLM"];

    if (options?.locale) {
      locale = options.locale;
    }

    // Resend the same question again
    if (options?.questionId) {
      messageId = options.questionId;
      nextQuestion = state.messages[messageId].request;
      chatId = state.messages[messageId].chatId;
      mode = state.messages[messageId].mode;
    }

    if (nextQuestion === "") {
      store.dispatch("app/warn", {
        message: "Please enter a question before submitting.",
      });
      return;
    }

    if (!options) {
      commit("resetNextQuestion");
    }

    commit("addMessage", {
      request: nextQuestion,
      messageId,
      chatId,
      mode,
      llm: llm,
    });

    dispatch("askEventPipeline", {
      messageId,
      nextQuestion,
      chatId,
      locale,
    })
      .then(() => {
        // Once the initial processing is done, generate follow-up questions
        return dispatch("generateFollowUpQuestions", {
          chatId,
          messageId,
          llm,
          locale,
        });
      })
      .catch((error) => {
        console.error("Error in askQuestion pipeline: ", error);
      });
  },
  askEventPipeline(
    { state, commit, getters, rootGetters, dispatch },
    { messageId, nextQuestion, chatId, locale }
  ): Promise<void> {
    const params = new URLSearchParams();
    params.append(
      "data",
      JSON.stringify({
        query: nextQuestion,
        llm: rootGetters["availableLLMs/selectedLLM"],
        chat_id: chatId,
        locale: locale,
      })
    );

    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const endpoint = getEndpoint(`/api/chat/ask?${params.toString()}`);
          const eventSource = await authenticatedEventSource.create(endpoint);

          eventSource.onopen = () => {
            eventSources[state.messages[messageId].chatId] = {
              event: eventSource,
              messageId,
            };
            if (state.messages[messageId].stopped) dispatch("stopGeneration");
          };

          eventSource.onmessage = (event) => {
            const parse = JSON.parse(event.data);
            const type = parse.type;
            const data = parse.data;
            switch (type) {
              case "FOLLOWUPQUESTIONS": {
                const questions = data;
                commit("setFollowQuestions", {
                  questions: Array.isArray(questions) ? questions : [],
                  messageId,
                });
                break;
              }
              case "GENERATION_FINISHED":
                commit("setFinishedLoading", {
                  value: true,
                  messageId,
                });
                break;
              case "GENERATION":
                commit("addToMessage", { messageId, token: data });
                break;
              case "ERROR":
                commit("setReply", {
                  reply: t("chat.error_message"),
                  messageId,
                  error: true,
                });
                commit("persist");
                reject(new Error("Generation error"));
                break;
              case "FINISHED":
                // close the event source
                eventSource.close();
                commit("setFinishedLoading", {
                  value: true,
                  messageId,
                });
                setTimeout(() => {
                  commit("persist");
                }, 3000);
                resolve();
                break;
              case "FINAL_RESPONSE":
                commit("setFinalResponse", { messageId: messageId, ...data });
                break;
              default:
                console.error("Unknown event type: ", data.type);
                break;
            }
          };

          eventSource.onerror = () => {
            commit("setReply", {
              reply: t("chat.error_message"),
              messageId,
              error: true,
            });
            commit("persist");
            eventSource.close();
            reject(new Error("EventSource error"));
          };
        } catch (error) {
          commit("setReply", {
            reply: t("chat.error_message"),
            messageId,
            error: true,
          });
          commit("persist");
          reject(error);
        }
      })();
    });
  },
  async generateFollowUpQuestions({ commit }, { chatId, llm, locale, messageId }) {
    const params = new URLSearchParams();
    params.append(
      "data",
      JSON.stringify({
        chat_id: chatId,
        llm: llm,
        locale: locale,
      })
    );

    try {
      const response = await authenticatedAxios.get(
        getEndpoint(`/api/chat/follow_up_questions?${params.toString()}`)
      );
      const questions = response.data.follow_up_questions;
      commit("setFollowQuestions", {
        questions: Array.isArray(questions) ? questions : [],
        messageId,
      });
      commit("persist");
    } catch (error) {
      console.error("Error generating follow-up questions: ", error);
    }
  },
  /**
   * Submits user feedback to the server asynchronously and updates the chat feedback state in Vuex.
   *
   * @param {Object} context Contains Vuex `commit` for mutations and `rootGetters` for accessing global state, particularly user authentication.
   * @param {Object} feedback The feedback object to be submitted, structured according to the server's expected format.
   */
  async giveFeedback({ commit, getters, rootGetters }, { feedback }) {
    try {
      store.commit("app/showLoadingOverlay");
      const { data } = await authenticatedAxios.post(
        getEndpoint("/api/give_feedback"),
        {
          feedback,
        },
        {
          timeout: 120000,
        }
      );
      store.commit("app/hideLoadingOverlay");
      if (!getters.showFullScreenFeedback) {
        await commit("showFullScreenFeedback", {
          thumbsChoice: feedback.thumbs_choice,
          entryId: feedback.entry_id,
        });
      } else {
        await commit("hideFullScreenFeedback");
      }
    } catch (e) {
      store.commit("app/hideLoadingOverlay");
      commit("persist");
    }
  },
  async uploadDocument({ commit, getters, rootGetters }, { file }) {
    const chatId = getters.currentChatId;
    if (!file) {
      store.dispatch("app/warn", {
        message: "No file selected",
      });
      return;
    }

    const allowedExtension = ".pdf";
    if (!file.name.toLowerCase().endsWith(allowedExtension)) {
      store.dispatch("app/warn", {
        message: "Only PDF files are allowed",
      });
      return;
    }

    const tempHash = v4();
    const chatDocument: ChatDocument = {
      ...initialChatDocument,
      title: file.name,
      hash: tempHash,
    };
    commit("addChatDocument", { chatId, document: chatDocument });
    commit("setDocumentDoneUploading", { document: chatDocument, doneUploading: false });
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    formData.append("chatId", chatId);
    try {
      const { data } = await authenticatedAxios.post(
        getEndpoint("/api/chat/upload_document"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 120000,
        }
      );
      const updatedDocument = {
        ...chatDocument,
        totalTokens: data.tokens,
        doneUploading: true,
        link: data.uri ?? "",
        hash: data.hash,
      };
      commit("removeChatDocument", { chatId, hash: tempHash });
      commit("addChatDocument", {
        chatId,
        document: updatedDocument,
      });
      commit("persist");
      commit("setMode", ChatMode.Document);
    } catch (error) {
      const updatedDocument: ChatDocument = {
        ...chatDocument,
        doneUploading: true,
        errorMessage: "document_preview.error_message",
      };

      commit("updateChatDocument", { chatId, hash: tempHash, newDocument: updatedDocument });
      return;
    }
  },
  async removeDocumentFromChat(
    { commit, getters, rootGetters },
    { chatId, document }: { chatId: string; document: ChatDocument }
  ) {
    try {
      if (!document.errorMessage) {
        const endpoint = getEndpoint("/api/chat/remove_document");
        const response = await authenticatedAxios.post(endpoint, {
          chat_id: chatId,
          hash: document.hash,
        });

        if (response.status !== 200) {
          console.error("Failed to delete the document from Firestore");
          return;
        }
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      return;
    }
    commit("removeChatDocument", { chatId, hash: document.hash });
    commit("persist");
    if (getters.currentChatDocuments.length === 0) {
      commit("setMode", ChatMode.Conversation);
    }
  },
  async removeChatInstance({ dispatch, commit, state }, { chatId }: { chatId: string }) {
    const chat = state.chats[chatId];

    if (chat && chat.documents) {
      const documentValues = Object.values(chat.documents);
      const documentRemovalPromises = documentValues.map(async (document) => {
        try {
          await dispatch("removeDocumentFromChat", { chatId, document });
        } catch (e) {
          console.error("Error removing document from chat", e);
        }
      });
      await Promise.all(documentRemovalPromises);
    }

    commit("deleteChat", chatId);
  },
  addChat({ commit }) {
    const chat = {
      id: v4(),
      name: "Ask me anything...",
      llm: undefined,
      nextQuestion: "",
      mode: ChatMode.Conversation,
      documents: {},
    };
    commit("pushChat", chat);
    commit("persist");

    return chat.id;
  },
  async stopGeneration({ commit, getters, state }) {
    // Set all messages to finished loading
    const chatId = state.currentChat;
    // Remove event stream

    if (eventSources[chatId]) {
      eventSources[chatId].event.close();
      const messageId = eventSources[chatId].messageId;
      commit("setFinishedLoading", {
        value: true,
        messageId,
        stopped: true,
      });
      delete eventSources[chatId];
    } else {
      getters.chatHistoryFromId(chatId).forEach(({ id }: Message) => {
        if (!state.messages[id].finishedLoading) {
          commit("setFinishedLoading", {
            value: true,
            messageId: id,
            stopped: true,
          });
        }
      });
    }
    commit("persist");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
