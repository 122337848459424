import { isBackendURL, openNewWindow, tokenNeedsRefresh } from "./iap-helpers";

import checkAuth from "./checkAuth";
import config from "@/customer_configs_flags/config";
import store from "@/store";
import { auth } from "../main";

class authenticatedEventSource extends EventSource {
  constructor(url: string) {
    super(url);
  }

  static async create(endpoint: string): Promise<authenticatedEventSource> {
    const urlObject = new URL(endpoint);

    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      urlObject.searchParams.append("auth", token);
    }

    if (config.enableIAPCookies && isBackendURL(endpoint) && tokenNeedsRefresh(endpoint)) {
      const clonedUrlObject = new URL(urlObject.toString());
      clonedUrlObject.searchParams.append("iap-authentication", "true");
      clonedUrlObject.searchParams.delete("data");
      await openNewWindow(clonedUrlObject.toString());

      sessionStorage["__Host-GCP_IAP_AUTH_TOKEN_FOR_" + endpoint] = new Date().getTime();
    }
    const eventSource = new authenticatedEventSource(urlObject.toString());
    eventSource.onerror = (event) => {
      checkAuth(event.target);
    };
    return eventSource;
  }
}

export default authenticatedEventSource;
